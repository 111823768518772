import React, { useState, useEffect } from 'react';
import { useUser } from '../../contexts/userContext'
import { useCard } from '../../contexts/cardContext'
import { useColumns } from '../../contexts/columnsContext';
import { format, parseISO } from 'date-fns';

// ICONS
import { GrTask } from "react-icons/gr";
import { MdLink, MdEmail, MdFolder, MdDeleteForever, MdCreditCard, MdLibraryAdd, MdAutoAwesomeMotion, MdOutlineSendToMobile, MdLockOpen, MdLockOutline, MdBookmark, MdAssignment, MdAssignmentTurnedIn, MdAdsClick, MdAddShoppingCart, MdAttachFile, MdViewColumn, MdWhatsapp, MdEdit, MdOutlineHistory, MdAnalytics, MdCreate, MdOutlineUpdate, Md360, MdWindow, MdRoom, MdShoppingCart, MdThumbDown, MdThumbUp, MdShare, MdHome, MdAccountBox, MdAlternateEmail, MdGrade, MdAccountBalance, MdArticle } from "react-icons/md";

import { FaBell } from "react-icons/fa";

import './style.css';

// API
import axios from 'axios';
import { apiUrl, fileApiUrl } from '../../config/apiConfig';

import { differenceInCalendarDays } from 'date-fns';

import { Draggable } from 'react-beautiful-dnd';
import VendaPerdida from '../VendaPerdida';

import EmailConversation from '../forms/EmailConversation';

import PedidoPedido from '../ModuloPedido';

import LogoFaktory from '../../assets/logo-faktory.png'

import Faktory from '../Faktory';
import api from '../../services/api';


function PreviewCard({ cardData, index }) {

  const { user, afilhadosList, listAllUsers, getAccessLevel, empresa, openCloseCustomModule, setOpenCloseCustomModule } = useUser();
  const { setOpenCloseUpdateCard, setCurrentCardData,
    setCards, setPreviewSearchCards,
    setListNotifications,
    setOpenCloseHistoricModal,
    setOpenCloseTarefasModal,
    setOpenCloseCompartilharModal,
    setOpenCloseModuloEsquadriasModal,
    addHistoricoCardContext,
    cards,
    currentCardIdMessage, setCurrentCardIdMessage,
    openCloseModalMessenger, setOpenCloseModalMessenger,
    openCloseAnexosModal, setOpenCloseAnexosModal,
    currentModuleCard, setCurrentModuleCard,
    openClosePedidosModal, setOpenClosePedidosModal
  } = useCard();

  const { columnsUser, columns, editableColumns, listaEtiquetas, setListaEtiquetas, showFaktoryModule, setShowFaktoryModule } = useColumns();

  const [potencialVenda, setPotencialVenda] = useState(1)
  const [showCard, setShowCard] = useState(false);
  const [statusCard, setStatusCard] = useState('');
  const [openCloseEditStatusModal, setOpenCloseEditStatusModal] = useState(false)
  const [selectedColumnId, setSelectedColumnId] = useState(cardData.column_id);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const [blockColumnCard, setBlockColumnCard] = useState(false);


  const [selectedEtiquetaId, setSelectedEtiquetaId] = useState(null);





  useEffect(() => {
    setPotencialVenda(cardData.potencial_venda)
    setStatusCard(cardData.status)
  }, [cardData])

  const openCompartilharModal = (e) => {
    e.stopPropagation();
    setCurrentCardData(cardData)
    setOpenCloseCompartilharModal(true)
  };

  // const openModuloEsquadriasModal = (e) => {
  //   e.stopPropagation();
  //   setCurrentCardData(cardData)
  //   setOpenCloseModuloEsquadriasModal(true)
  // };

  const openModuloEsquadriasModal = (e) => {
    e.stopPropagation();
    // if (cardData.status !== 'Vendido') {
    //   alert("O formulário de esquadrias só pode ser aberto para cards com status 'Vendido'.");
    //   return; // Se o status não for 'Vendido', a função termina aqui.
    // }
    setCurrentCardData(cardData);
    setOpenCloseModuloEsquadriasModal(true);
  };


  const openAnexosModal = (e) => {
    e.stopPropagation();
    if (!user.is_premium) {
      alert("Você não é Premium");
      return; // Adicione este return para evitar a abertura do modal
    }
    setCurrentCardData(cardData);
    setOpenCloseAnexosModal(true);
  };



  const openHistoricModal = (e) => {
    e.stopPropagation();
    setCurrentCardData(cardData)
    setOpenCloseHistoricModal(true)
  };

  const openTarefasModal = (e) => {
    e.stopPropagation();
    setCurrentCardData(cardData)
    setOpenCloseTarefasModal(true)
  };


  // const openClosePedidos = (e) => {
  //   e.stopPropagation();
  //   setCurrentCardData(cardData)
  //   setOpenClosePedidosModal(true)
  // };

  // const openClosePedidos = (e) => {
  //   e.stopPropagation();
  //   if (cardData.status !== 'Vendido') {
  //     alert("O formulário de pedidos só pode ser aberto para cards com status 'Vendido'.");
  //     return; // Se o status não for 'Vendido', a função termina aqui.
  //   }

  //   console.log(cardData.origem)

  //   if (cardData.origem == '' || cardData.origem == null || cardData.origem == 'Sem Origem') {
  //     alert("Imforme a ORIGEM no card!");
  //     return; // Se o status não for 'Vendido', a função termina aqui.
  //   }

  //   const resposta = window.confirm("Não esqueça de anexar a Proposta Comercial!");

  //   if (resposta) { // Se clicar em "OK" (Sim)
  //     setCurrentCardData(cardData);
  //     setOpenClosePedidosModal(true);
  //   }

  // };

  // const openClosePedidos = (e) => {
  //   e.stopPropagation();
  
  //   if (cardData.status !== 'Vendido') {
  //     alert("O formulário de pedidos só pode ser aberto para cards com status 'Vendido'.");
  //     return;
  //   }
  
  //   if (!cardData.origem || cardData.origem === '' || cardData.origem === 'Sem Origem') {
  //     alert("Informe a ORIGEM no card!");
  //     return;
  //   }
  
  //   // Validações adicionais
  //   const requiredFields = ['name', 'email', 'fone', 'endereco', 'city', 'state'];
  //   for (const field of requiredFields) {
  //     if (!cardData[field] || cardData[field].toString().trim().length < 1) {
  //       alert(`O campo '${field}' precisa estar preenchido!`);
  //       return;
  //     }
  //   }
  
  //   if (
  //     cardData.cost_value == null || isNaN(cardData.cost_value) || Number(cardData.cost_value) <= 0 ||
  //     cardData.sale_value == null || isNaN(cardData.sale_value) || Number(cardData.sale_value) <= 0
  //   ) {
  //     alert("Os valores de custo e venda devem ser maiores que zero!");
  //     return;
  //   }
  
  //   const resposta = window.confirm("Não esqueça de anexar a Proposta Comercial!");
  
  //   if (resposta) {
  //     setCurrentCardData(cardData);
  //     setOpenClosePedidosModal(true);
  //   }
  // };
  

  const openClosePedidos = (e) => {
    e.stopPropagation();
  
    const isAdmin = user?.access_level === 5; // ajuste se seu sistema usar outro valor para admin
  
    if (cardData.status !== 'Vendido') {
      alert("O formulário de pedidos só pode ser aberto para cards com status 'Vendido'.");
      return;
    }
  
    if (!isAdmin && (!cardData.origem || cardData.origem === '' || cardData.origem === 'Sem Origem')) {
      alert("Informe a ORIGEM no card!");
      return;
    }
  
    // Validações adicionais (exceto para admin)
    if (!isAdmin) {
      const requiredFields = ['name', 'email', 'fone', 'endereco', 'city', 'state'];
      for (const field of requiredFields) {
        if (!cardData[field] || cardData[field].toString().trim().length < 1) {
          alert(`O campo '${field}' precisa estar preenchido!`);
          return;
        }
      }
  
      if (
        cardData.cost_value == null || isNaN(cardData.cost_value) || Number(cardData.cost_value) <= 0 ||
        cardData.sale_value == null || isNaN(cardData.sale_value) || Number(cardData.sale_value) <= 0
      ) {
        alert("Os valores de custo e venda devem ser maiores que zero!");
        return;
      }
    }
  
    const resposta = window.confirm("Não esqueça de anexar a Proposta Comercial!");
  
    if (resposta) {
      setCurrentCardData(cardData);
      setOpenClosePedidosModal(true);
    }
  };





  const viewCard = (e) => {
    e.stopPropagation();
    if (!showCard) {
      fetchCardDetails(cardData.card_id);
      fetchModules();
    }

    setShowCard(!showCard);
  };



  function formatDate(dateString) {
    const date = parseISO(dateString);
    return format(date, 'dd/MM/yyyy - HH:mm:ss');
  }

  const getUsernameById = (entityId) => {

    if (!user) {
      return 'Erro: Dados necessários não estão disponíveis.';
    }

    const entidade = listAllUsers.find(afilhado => afilhado.id === entityId);
    if (entidade && entidade.username) {
      return entidade.username;
    }

    if (entityId === user.id) {
      return user.username;
    }

    return 'id não encontrado';
  };

  function updatePotencialVenda(event, number) {
    event.stopPropagation();
    setPotencialVenda(number)
    handleUpdatePotencialVenda(number)
  }


  // const handleUpdatePotencialVenda = async (potencial) => {
  //   if (!getAccessLevel('estrelas')) {
  //     const confirmDelete = window.alert('Não autorizado pelo Administrador!');
  //     return;
  //   }

  //   const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
  //   if (!userConfirmed) {
  //     return;
  //   }

  //   const currentCard = {
  //     id: cardData.card_id,
  //     potencial_venda: potencial,
  //   };

  //   try {
  //     setModalLoading(true)
  //     const response = await axios.post(`${apiUrl}/card/update-potencial-venda`, currentCard);
  //     // Atualiza os cards
  //     setCards(prevCards => prevCards.map(card => card.card_id === currentCard.card_id ? { ...card, ...response.data } : card));
  //     setPreviewSearchCards(prevCards => prevCards.map(card => card.card_id === currentCard.card_id ? { ...card, ...response.data } : card));
  //     setListNotifications(prevCards => prevCards.map(card => card.card_id === currentCard.card_id ? { ...card, ...response.data } : card));
  //     addHistoricoCardContext(`Potencial de Venda alterado para ${potencial} estrelas`, cardData.card_id, user.id)
  //     setModalLoading(false)
  //   } catch (error) {
  //     console.error('Erro ao Atualizar potencial de venda:', error);
  //     setMensagemLoading('Erro ao Salvar Status!')
  //   }
  // };

  const handleUpdatePotencialVenda = async (potencial) => {
    if (!getAccessLevel('estrelas')) {
      alert('Não autorizado pelo Administrador!');
      return;
    }

    const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
    if (!userConfirmed) {
      return;
    }

    const currentCard = {
      id: cardData.card_id,
      potencial_venda: potencial,
    };

    try {
      setModalLoading(true);

      // ✅ Utilizando `api.post` para incluir automaticamente o token JWT
      const response = await api.post(`/card/update-potencial-venda`, currentCard);

      // Atualiza os cards nos estados globais
      setCards(prevCards =>
        prevCards.map(card =>
          card.card_id === currentCard.id ? { ...card, ...response.data } : card
        )
      );

      setPreviewSearchCards(prevCards =>
        prevCards.map(card =>
          card.card_id === currentCard.id ? { ...card, ...response.data } : card
        )
      );

      setListNotifications(prevCards =>
        prevCards.map(card =>
          card.card_id === currentCard.id ? { ...card, ...response.data } : card
        )
      );

      addHistoricoCardContext(
        `Potencial de Venda alterado para ${potencial} estrelas`,
        cardData.card_id,
        user.id
      );

      setModalLoading(false);
    } catch (error) {
      console.error('Erro ao Atualizar potencial de venda:', error);
      setMensagemLoading('Erro ao Salvar Status!');
    }
  };



  function getDaysSinceUpdate(updatedAt) {
    const today = new Date();
    const lastUpdateDate = parseISO(updatedAt);
    return differenceInCalendarDays(today, lastUpdateDate);
  }

  function getCardData(e) {
    e.stopPropagation();
    setCurrentCardData(cardData)
    setOpenCloseUpdateCard(true)
  }

  function openCloseEditEstatusCard(event) {
    event.stopPropagation();
    if (!getAccessLevel('status')) {
      const confirmDelete = window.alert('Não autorizado pelo Administrador!');
      return
    }

    setStatusCard(cardData.status)

    setOpenCloseEditStatusModal(true)
  }



  // const updateCardStatus = async (id, status, event) => {
  //   event.stopPropagation();

  //   // Verifica se o status já é "Vendido"
  //   if (cardData.status === 'Vendido' && status === 'Vendido') {
  //     alert('Este card já está marcado como "Vendido".');
  //     return; // Impede a alteração
  //   }

  //   const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
  //   if (!userConfirmed) {
  //     return;
  //   }




  //   const vendidosColumn = columns.find(column => column.name === empresa.coluna_vendido);
  //   let columnId = vendidosColumn && status != null ? vendidosColumn.id : cardData.column_id;

  //   try {
  //     setModalLoading(true);
  //     const response = await axios.post(`${apiUrl}/card/update-status`, { id, status, columnId });
  //     setStatusCard(response.data.status);
  //     setCards(prevCards => prevCards.map(card => card.card_id === id ? { ...card, ...response.data } : card));
  //     setPreviewSearchCards(prevCards => prevCards.map(card => card.card_id === id ? { ...card, ...response.data } : card));
  //     setListNotifications(prevCards => prevCards.map(card => card.card_id === id ? { ...card, ...response.data } : card));
  //     openCloseEditEstatusCard(event);
  //     addHistoricoCardContext(`Status alterado de ${cardData.status} para ${status != null ? status : 'Sem Status'}`, cardData.card_id, user.id);
  //     setModalLoading(false);
  //   } catch (error) {
  //     console.error('Erro ao atualizar o cartão:', error);
  //     setMensagemLoading('Erro ao Salvar Status!');
  //   }
  // };


  const updateCardStatus = async (id, status, event) => {
    event.stopPropagation();

    // Verifica se o status já é "Vendido"
    if (cardData.status === "Vendido" && status === "Vendido") {
      alert('Este card já está marcado como "Vendido".');
      return; // Impede a alteração
    }

    const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
    if (!userConfirmed) {
      return;
    }

    const vendidosColumn = columns.find(
      (column) => column.name === empresa.coluna_vendido
    );
    let columnId =
      vendidosColumn && status != null ? vendidosColumn.id : cardData.column_id;

    try {
      setModalLoading(true);
      const response = await api.post("/card/update-status", {
        id,
        status,
        columnId,
      });

      setStatusCard(response.data.status);
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === id ? { ...card, ...response.data } : card
        )
      );
      setPreviewSearchCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === id ? { ...card, ...response.data } : card
        )
      );
      setListNotifications((prevCards) =>
        prevCards.map((card) =>
          card.card_id === id ? { ...card, ...response.data } : card
        )
      );

      openCloseEditEstatusCard(event);
      addHistoricoCardContext(
        `Status alterado de ${cardData.status} para ${status != null ? status : "Sem Status"
        }`,
        cardData.card_id,
        user.id
      );
      setModalLoading(false);
    } catch (error) {
      console.error("Erro ao atualizar o cartão:", error);
      setMensagemLoading("Erro ao Salvar Status!");
    }
  };



  const [openCloseModalVendaPerdida, setOpenCloseModalVendaPerdida] = useState(false)

  const closeVendaPerdidaModal = () => {
    setOpenCloseModalVendaPerdida(false);
  };

  const updateCardStatusPerdido = async (id, status, event) => {
    event.stopPropagation();
    setCurrentCardData(cardData)
    setOpenCloseEditStatusModal(false)
    setOpenCloseModalVendaPerdida(true)
  };

  function abrirWhatsApp(fone) {
    const confirmDelete = window.confirm('Iniciar conversa pelo WhatsApp?');
    if (confirmDelete) {
      const numeroLimpo = fone.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
      const codigoPais = '+55'; // Código do país (Brasil)
      const numeroTelefone = codigoPais + numeroLimpo;

      // Preparando uma mensagem padrão
      const mensagemPadrao = encodeURIComponent(`Olá, gostaria de discutir mais sobre a oportunidade: ${cardData.name}, ${cardData.city}/${cardData.state}. Meu nome é ${user.username}`);

      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      if (isMobile) {
        window.location.href = `https://api.whatsapp.com/send?phone=${numeroTelefone}&text=${mensagemPadrao}`;
      } else {
        window.open(`https://web.whatsapp.com/send?phone=${numeroTelefone}&text=${mensagemPadrao}`, '_blank');
      }
      addHistoricoCardContext(`Conversa iniciada pelo WhatsApp ${cardData.fone}`, cardData.card_id, user.id)

    }
  }

  // const getNameColumnCard = (idColumn) => {

  //   if (!columnsUser) {
  //     return 'Dados ainda estão carregando...';
  //   }

  //   const nameColumn = columnsUser.find((item) => item.id.toString() === idColumn);

  //   return nameColumn ? nameColumn.name : 'Nome não encontrado';
  // };

  const getNameColumnCard = (idColumn) => {
    if (!columnsUser) {
      return 'Dados ainda estão carregando...';
    }

    // Converter os valores para números antes de comparar
    const nameColumn = columnsUser.find((item) => Number(item.id) === Number(idColumn));

    return nameColumn ? nameColumn.name : 'Nome não encontrado';
  };


  const [modalLoading, setModalLoading] = useState(false)
  const [mensagemLoading, setMensagemLoading] = useState('Atualizando...')



  const updateCardColumn = async (newColumnId) => {
    const currentCardId = cardData.card_id;

    const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
    if (!userConfirmed) {
      return;
    }

    if (!cardData.city || !cardData.state) {
      alert("Não é possível mover o card. Cidade e/ou Estado não preenchidos.");
      return;
    }

    try {
      setModalLoading(true);
      setShowConfirmButton(true);
      setMensagemLoading("Alterando Coluna...");

      const response = await api.post("/card/update-column", {
        cardId: currentCardId,
        columnId: newColumnId,
      });


      // Verifica se a API retornou sucesso
      if (response.status === 201) {

        alert("Coluna alterada com sucesso!");

        addHistoricoCardContext(
          `Coluna alterada para ${getNameColumnCard(newColumnId)}`,
          currentCardId,
          user.id
        );
  
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.card_id === currentCardId ? { ...card, ...response.data } : card
          )
        );
        setPreviewSearchCards((prevCards) =>
          prevCards.map((card) =>
            card.card_id === currentCardId ? { ...card, ...response.data } : card
          )
        );

        

      } else {
        // Caso a API não retorne sucesso, dispara um erro
        console.log(response.status, response.data.success)
        throw new Error("Falha na atualização da coluna");
      }


      // Alterar a etiqueta para id == 1
      updateEtiqueta(null);

      setModalLoading(false);
      setMensagemLoading("");
      setShowConfirmButton(false);
    } catch (error) {
      setMensagemLoading("Erro ao alterar Card de Coluna!");
      console.error("Erro ao atualizar a coluna do card:", error);

      setCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === currentCardId
            ? { ...card, column_id: cardData.column_id }
            : card
        )
      );

      alert("Falha ao mover o card, tente novamente.");
      setShowConfirmButton(false);
    }
  };



  const shareCard = (cardId, event) => {
    event.stopPropagation();
    setCurrentCardIdMessage(cardId);
    setOpenCloseModalMessenger(true)
  }


  const openFaktoryModule = (cardData) => {

    if (user.access_level !== 5) {
      alert("Você não tem permissão para acessar este módulo.");
      return;
    }


    // Converte o pedido_number para número
    const pedidoNumber = Number(cardData.pedido_number);

    // Verificação do pedido_number
    if (
      !cardData.pedido_number || // Verifica se é null ou vazio
      isNaN(pedidoNumber) || // Verifica se não é um número válido após a conversão
      pedidoNumber.toString().length < 3 // Verifica se tem menos de 3 caracteres
    ) {
      alert("O número do pedido é inválido. Verifique e tente novamente.");
      return;
    }

    // Se o pedido_number for válido, abre o módulo
    setCurrentCardData(cardData);
    setShowFaktoryModule(true);
  }






  function getEtiquetaColorById(id) {
    const etiqueta = listaEtiquetas.find(etiqueta => etiqueta.id === id);
    return etiqueta ? etiqueta.color : '';
  }




  // const updateEtiqueta = async (etiqueta) => {

  //   const currentCardId = cardData.card_id;


  //   try {

  //     const response = await axios.post(`${apiUrl}/card/update-etiqueta`, {
  //       cardId: currentCardId,
  //       etiqueta_id: etiqueta,
  //     });



  //     setCards(prevCards => prevCards.map(card => card.card_id === currentCardId ? { ...card, ...response.data } : card));
  //     setPreviewSearchCards(prevCards => prevCards.map(card => card.card_id === currentCardId ? { ...card, ...response.data } : card));
  //   } catch (error) {
  //     console.log('Erro ao alterar Etiqueta!');

  //   }
  // };
  const updateEtiqueta = async (etiqueta) => {
    const currentCardId = cardData.card_id;

    try {
      const response = await api.post("/card/update-etiqueta", {
        cardId: currentCardId,
        etiqueta_id: etiqueta,
      });

      setCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === currentCardId ? { ...card, ...response.data } : card
        )
      );
      setPreviewSearchCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === currentCardId ? { ...card, ...response.data } : card
        )
      );
    } catch (error) {
      console.error("Erro ao alterar Etiqueta:", error);
    }
  };




  // const updateCardEtiqueta = async (etiqueta) => {
  //   const currentCardId = cardData.card_id;

  //   const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
  //   if (!userConfirmed) {
  //     return;
  //   }


  //   try {
  //     setModalLoading(true);
  //     setShowConfirmButton(true);
  //     setMensagemLoading('Alterando Etiqueta...');
  //     const response = await axios.post(`${apiUrl}/card/update-etiqueta`, {
  //       cardId: currentCardId,
  //       etiqueta_id: etiqueta,
  //     });

  //     if (response.data) {
  //       addHistoricoCardContext(`Etiqueta alterada`, currentCardId, user.id);
  //     } else {
  //       throw new Error('No data returned');
  //     }

  //     setCards(prevCards => prevCards.map(card => card.card_id === currentCardId ? { ...card, ...response.data } : card));
  //     setPreviewSearchCards(prevCards => prevCards.map(card => card.card_id === currentCardId ? { ...card, ...response.data } : card));

  //     setModalLoading(false);
  //     setMensagemLoading('');
  //     setShowConfirmButton(false);
  //   } catch (error) {
  //     setMensagemLoading('Erro ao alterar Etiqueta!');
  //     setShowConfirmButton(false);
  //   }
  // };

  const updateCardEtiqueta = async (etiqueta) => {
    const currentCardId = cardData.card_id;

    const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
    if (!userConfirmed) {
      return;
    }

    try {
      setModalLoading(true);
      setShowConfirmButton(true);
      setMensagemLoading("Alterando Etiqueta...");

      const response = await api.post("/card/update-etiqueta", {
        cardId: currentCardId,
        etiqueta_id: etiqueta,
      });

      if (response.data) {
        addHistoricoCardContext(`Etiqueta alterada`, currentCardId, user.id);
      } else {
        throw new Error("Nenhum dado retornado");
      }

      setCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === currentCardId ? { ...card, ...response.data } : card
        )
      );
      setPreviewSearchCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === currentCardId ? { ...card, ...response.data } : card
        )
      );

      setModalLoading(false);
      setMensagemLoading("");
      setShowConfirmButton(false);
    } catch (error) {
      console.error("Erro ao alterar Etiqueta:", error);
      setMensagemLoading("Erro ao alterar Etiqueta!");
      setShowConfirmButton(false);
    }
  };



  const sortedEtiquetas = [...listaEtiquetas].sort((a, b) => a.order - b.order);
  const editableColumnIds = editableColumns.map(column => column.columnId);

  const canEditCurrentColumn = editableColumnIds.includes(cardData.column_id);







  // const updateBlockColumnCard = async (block) => {

  //   if (user.access_level != 5) {
  //     const userConfirmed = window.confirm(`Apenas Administrador!`);
  //     return;
  //   }



  //   const userConfirmed = window.confirm(`Você tem certeza que deseja alterar?`);
  //   if (!userConfirmed) {
  //     return;
  //   }

  //   const currentCard = {
  //     id: cardData.card_id,
  //     block_column: block,
  //   };

  //   try {
  //     setModalLoading(true)
  //     const response = await axios.post(`${apiUrl}/card/update-block-column`, currentCard);
  //     setCards(prevCards => prevCards.map(card => card.card_id === currentCard.card_id ? { ...card, ...response.data } : card));
  //     setPreviewSearchCards(prevCards => prevCards.map(card => card.card_id === currentCard.card_id ? { ...card, ...response.data } : card));
  //     setListNotifications(prevCards => prevCards.map(card => card.card_id === currentCard.card_id ? { ...card, ...response.data } : card));
  //     setModalLoading(false)
  //     setBlockColumnCard(block)
  //   } catch (error) {
  //     console.error('Erro ao Atualizar bloqueio de coluna:', error);
  //   }
  // };


  const updateBlockColumnCard = async (block) => {
    if (user.access_level !== 5) {
      alert("Apenas Administrador pode realizar essa ação!");
      return;
    }

    const userConfirmed = window.confirm("Você tem certeza que deseja alterar?");
    if (!userConfirmed) {
      return;
    }

    const currentCard = {
      id: cardData.card_id,
      block_column: block,
    };

    try {
      setModalLoading(true);

      const response = await api.post("/card/update-block-column", currentCard);

      setCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === currentCard.id ? { ...card, ...response.data } : card
        )
      );
      setPreviewSearchCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === currentCard.id ? { ...card, ...response.data } : card
        )
      );
      setListNotifications((prevCards) =>
        prevCards.map((card) =>
          card.card_id === currentCard.id ? { ...card, ...response.data } : card
        )
      );

      setModalLoading(false);
      setBlockColumnCard(block);
    } catch (error) {
      console.error("Erro ao atualizar bloqueio de coluna:", error);
      setModalLoading(false);
    }
  };




  useEffect(() => {
    setBlockColumnCard(cardData.block_column);
  }, [cardData.block_column]);






  // ---------------- Enviar whatsapp CHATBOT--------------------

  function enviarMensagemPotencialCliente() {

    const userConfirmed = window.confirm(`Emviar mensagem para Parceiro?`);
    if (!userConfirmed) {
      return;
    }


    enviarMensagemParaBotConversa(
      user.empresa_id,
      buscarFoneReferencia(cardData.entity_id),
      '',
      `${getUsernameById(cardData.entity_id)}\n \nCard com Link Patrocinado criado com sucesso no CRM! \n \nCliente: ${cardData.name} \nNúmero do orçamento: ${cardData.document_number} \nValor: ${cardData.sale_value ? parseFloat(cardData.sale_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}\n\n Verifique as informações fornecidas!\n\nBazze PVC`
    );
  }


  // const enviarMensagemParaBotConversa = async (empresaId, numero, contato, mensagem) => {
  //   try {
  //     await axios.post(`${apiUrl}/card/enviarMensagemParaBotConversa`, null, {
  //       params: {
  //         empresaId,
  //         numero,
  //         contato,
  //         mensagem,
  //       },
  //     });

  //     const userConfirmed = window.confirm('Mensagem enviada!');

  //   } catch (error) {
  //     console.error('Erro ao enviar mensagem para o BotConversa:', error.message);
  //   }
  // };

  const enviarMensagemParaBotConversa = async (empresaId, numero, contato, mensagem) => {
    try {
      await api.post("/card/enviarMensagemParaBotConversa", null, {
        params: {
          empresaId,
          numero,
          contato,
          mensagem,
        },
      });

      alert("Mensagem enviada!");
    } catch (error) {
      console.error("Erro ao enviar mensagem para o BotConversa:", error);
    }
  };




  // PEGAR FONE CORRESPONDENTE PREFWEB / SUITEFLOW
  function buscarFoneReferencia(entidade) {

    const listaFiltrada = listAllUsers.filter((item) => item.id.toString() === entidade.toString());

    const fone = listaFiltrada.map((item) => item.fone);

    return fone.toString();
  }



  // const criarEtapaDeProducao = async () => {

  //   if (!getAccessLevel('etapaProducao')) {
  //     const userConfirmed = window.alert(`Não autorizado pelo ADM!`);
  //     return;
  //   }


  //   const userConfirmed = window.confirm(`Criar etapa de Produção?`);
  //   if (!userConfirmed) {
  //     return;
  //   }


  //   try {
  //     const cardDataPayload = {
  //       created_at: new Date(),
  //       name: cardData.name ? cardData.name : '',
  //       document_number: cardData.document_number ? cardData.document_number : '',
  //       cost_value: 0,
  //       column_id: cardData.column_id,
  //       entity_id: cardData.entity_id,
  //       empresa_id: user.empresa_id,
  //       origem: cardData.origem ? cardData.origem : '',
  //       sale_value: 0,
  //       potencial_venda: cardData.potencial_venda,
  //       produto: cardData.produto,
  //       status: cardData.status,
  //       motivo_venda_perdida: cardData.motivo_venda_perdida,
  //       nivel_prioridade: cardData.nivel_prioridade,
  //       status_date: cardData.status_date,
  //       updated_at: cardData.updated_at,
  //       email: cardData.email ? cardData.email : '',
  //       fone: cardData.fone ? cardData.fone : '',
  //       state: cardData.state ? cardData.state : '',
  //       city: cardData.city ? cardData.city : '',
  //       pedido_number: cardData.pedido_number ? cardData.pedido_number : '',
  //       etapa_producao: cardData.etapa_producao + 1,
  //       etiqueta_id: cardData.etiqueta_id ? cardData.etiqueta_id : 1,
  //     };

  //     const response = await axios.post(`${apiUrl}/card/import-suiteflow`, cardDataPayload);
  //     setCards([...cards, response.data]);

  //     const userConfirmed = window.alert(`Etapa de produção criada com sucesso!`);

  //   } catch (error) {
  //     console.error('Erro ao criar etapa de produção');
  //     const userConfirmed = window.alert(`Erro ao criar etapa de Produção!`);
  //   }
  // };
  const criarEtapaDeProducao = async () => {
    if (!getAccessLevel("etapaProducao")) {
      alert("Não autorizado pelo ADM!");
      return;
    }

    const userConfirmed = window.confirm("Criar etapa de Produção?");
    if (!userConfirmed) {
      return;
    }

    try {
      const cardDataPayload = {
        created_at: new Date(),
        name: cardData.name || "",
        document_number: cardData.document_number || "",
        cost_value: 0,
        column_id: cardData.column_id,
        entity_id: cardData.entity_id,
        empresa_id: user.empresa_id,
        origem: cardData.origem || "",
        sale_value: 0,
        potencial_venda: cardData.potencial_venda,
        produto: cardData.produto,
        status: cardData.status,
        motivo_venda_perdida: cardData.motivo_venda_perdida,
        nivel_prioridade: cardData.nivel_prioridade,
        status_date: cardData.status_date,
        updated_at: cardData.updated_at,
        email: cardData.email || "",
        fone: cardData.fone || "",
        state: cardData.state || "",
        city: cardData.city || "",
        pedido_number: cardData.pedido_number || "",
        etapa_producao: cardData.etapa_producao + 1,
        etiqueta_id: cardData.etiqueta_id || 1,
      };

      const response = await api.post("/card/import-suiteflow", cardDataPayload);
      setCards([...cards, response.data]);

      alert("Etapa de produção criada com sucesso!");
    } catch (error) {
      console.error("Erro ao criar etapa de produção:", error);
      alert("Erro ao criar etapa de Produção!");
    }
  };




  // const excluirCard = async () => {
  //   if (!getAccessLevel('excluir')) {
  //     window.alert(`Não autorizado pelo ADM!`);
  //     return;
  //   }
  //   const userConfirmed = window.confirm(`Excluir Card?`);
  //   if (!userConfirmed) {
  //     return;
  //   }
  //   try {
  //     setModalLoading(true);
  //     setMensagemLoading('Excluindo anexos...');

  //     // Obtenha todos os anexos do card
  //     const anexosResponse = await axios.get(`${apiUrl}/card/${cardData.card_id}/anexos`);


  //     const anexos = anexosResponse.data;

  //     // Exclua todos os anexos do servidor de arquivos
  //     for (const anexo of anexos) {
  //       try {
  //         await axios.delete(`${fileApiUrl}/uploads/${anexo.nome_arquivo}`);


  //       } catch (error) {
  //         console.error(`Erro ao excluir o arquivo ${anexo.nome_arquivo} do servidor:`, error);
  //       }
  //     }

  //     setMensagemLoading('Excluindo anexos do banco de dados...');

  //     // Exclua todos os anexos do banco de dados
  //     await axios.delete(`${apiUrl}/card/${cardData.card_id}/delete-all-anexos`);

  //     setMensagemLoading('Excluindo card...');

  //     // Exclua o card
  //     await axios.delete(`${apiUrl}/card/${cardData.card_id}`);
  //     setCards(prevCards => prevCards.filter(card => card.card_id !== cardData.card_id));

  //     window.alert(`Card excluído com sucesso!`);
  //   } catch (error) {
  //     console.error('Erro ao excluir o card:', error);
  //     window.alert(`Erro ao excluir o card!`);
  //   } finally {
  //     setModalLoading(false);
  //     setMensagemLoading('');
  //   }
  // };






  const excluirCard = async () => {
    if (!getAccessLevel("excluir")) {
      alert("Não autorizado pelo ADM!");
      return;
    }

    const userConfirmed = window.confirm("Excluir Card?");
    if (!userConfirmed) {
      return;
    }

    try {
      setModalLoading(true);
      setMensagemLoading("Excluindo anexos...");

      // Obtenha todos os anexos do card
      const anexosResponse = await api.get(`/card/${cardData.card_id}/anexos`);
      const anexos = anexosResponse.data;

      // Exclua todos os anexos do servidor de arquivos
      for (const anexo of anexos) {
        try {
          await api.delete(`/uploads/${anexo.nome_arquivo}`);
        } catch (error) {
          console.error(
            `Erro ao excluir o arquivo ${anexo.nome_arquivo} do servidor:`,
            error
          );
        }
      }

      setMensagemLoading("Excluindo anexos do banco de dados...");

      // Exclua todos os anexos do banco de dados
      await api.delete(`/card/${cardData.card_id}/delete-all-anexos`);

      setMensagemLoading("Excluindo card...");

      // Exclua o card
      await api.delete(`/card/${cardData.card_id}`);
      setCards((prevCards) =>
        prevCards.filter((card) => card.card_id !== cardData.card_id)
      );

      alert("Card excluído com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir o card:", error);
      alert("Erro ao excluir o card!");
    } finally {
      setModalLoading(false);
      setMensagemLoading("");
    }
  };


  function getBackgroundColor(days) {
    if (days <= 7) {
      return '#72D419'; //#72D419#00C7E2
    } else if (days > 7 && days <= 30) {
      return '#F7B304';
    } else if (days > 30) {
      return '#F51148';
    } else {
      return 'rgb(255, 20, 98)';
    }
  }



  // const fetchCardDetails = async (cardId) => {
  //   try {
  //     setModalLoading(true)
  //     const response = await axios.get(`${apiUrl}/card/find-by-id/${cardId}`);
  //     const updatedCard = response.data;
  //     setCards(prevCards =>
  //       prevCards.map(card =>
  //         card.card_id === updatedCard.card_id ? { ...card, ...updatedCard } : card
  //       )
  //     );
  //     setModalLoading(false)
  //   } catch (error) {
  //     console.error('Erro ao buscar detalhes do card:', error);
  //   }
  // };

  const fetchCardDetails = async (cardId) => {
    try {
      setModalLoading(true);

      const response = await api.get(`/card/find-by-id/${cardId}`);
      const updatedCard = response.data;

      setCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === updatedCard.card_id ? { ...card, ...updatedCard } : card
        )
      );

      setModalLoading(false);
    } catch (error) {
      console.error("Erro ao buscar detalhes do card:", error);
      setModalLoading(false);
    }
  };



  const [showEmails, setShowEmails] = useState(false);

  const handleEmailButtonClick = () => {
    setShowEmails(!showEmails);
  };



  const [showPedidos, setShowPedidos] = useState(false);

  const handlePedidosButtonClick = (openClose) => {
    setShowPedidos(openClose)
  };


  const handleCustomModuleButtonClick = (event, Module) => {
    setCurrentModuleCard(Module);
    event.stopPropagation();
    setCurrentCardData(cardData)

    setOpenCloseCustomModule(true);
  };







  const [modules, setModules] = useState([]);


  // const fetchModules = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/custom-modules/list-modules`, {
  //       params: { empresa_id: user.empresa_id }
  //     });
  //     setModules(response.data);
  //   } catch (error) {
  //     console.error('Erro ao listar módulos:', error);
  //     setModules([]);
  //   }
  // };

  const fetchModules = async () => {
    try {
      const response = await api.get("/custom-modules/list-modules", {
        params: { empresa_id: user.empresa_id },
      });
      setModules(response.data);
    } catch (error) {
      console.error("Erro ao listar módulos:", error);
      setModules([]);
    }
  };


  // const createNewModule = async () => {
  //   const moduleData = {
  //     name: newModuleName,
  //     empresa_id: user.empresa_id,
  //     // outros dados do módulo
  //   };

  //   try {
  //     await axios.post(`${apiUrl}/custom-modules/create-module`, moduleData);
  //     fetchModules();
  //     setShowNewModuleContainer(false);
  //     setNewModuleName('');
  //   } catch (error) {
  //     console.error('Erro ao criar módulo:', error);
  //   }
  // };

  const createNewModule = async () => {
    const moduleData = {
      name: newModuleName,
      empresa_id: user.empresa_id,
      // outros dados do módulo
    };

    try {
      await api.post("/custom-modules/create-module", moduleData);
      fetchModules();
      setShowNewModuleContainer(false);
      setNewModuleName("");
    } catch (error) {
      console.error("Erro ao criar módulo:", error);
    }
  };



  const [showNewModuleContainer, setShowNewModuleContainer] = useState(false);
  const [newModuleName, setNewModuleName] = useState('');


  const [linkCopied, setLinkCopied] = useState(false);

  const copyTrackingLink = (event) => {
    event.stopPropagation();
    const trackingLink = `${window.location.origin}/tracking/${cardData.card_id}`;
    navigator.clipboard.writeText(trackingLink);
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 3000); // Esconde a mensagem após 3 segundos
  };


  //console.log(user)



  // const updateTemperaturaCard = async (temperatura) => {

  //   if (user.access_level !== 5) {
  //     alert("Apenas administradores podem alterar a temperatura.");
  //     return;
  //   }

  //   try {
  //     const response = await axios.put(`${apiUrl}/card/temperatura-card/${cardData.card_id}`, {
  //       temperatura_card: temperatura,
  //     });

  //     setCards((prevCards) =>
  //       prevCards.map((card) =>
  //         card.card_id === cardData.card_id ? { ...card, temperatura_card: temperatura } : card
  //       )
  //     );

  //   } catch (error) {
  //     console.error('Erro ao atualizar temperatura:', error);
  //   }
  // };

  const updateTemperaturaCard = async (temperatura) => {
    if (user.access_level !== 5) {
      alert("Apenas administradores podem alterar a temperatura.");
      return;
    }

    try {
      const response = await api.put(`/card/temperatura-card/${cardData.card_id}`, {
        temperatura_card: temperatura,
      });

      setCards((prevCards) =>
        prevCards.map((card) =>
          card.card_id === cardData.card_id ? { ...card, temperatura_card: temperatura } : card
        )
      );
    } catch (error) {
      console.error("Erro ao atualizar temperatura:", error);
    }
  };






  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (e) => {
    e.stopPropagation();
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    setIsHovered(false);
  };






  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, text: '' });
  const [tooltipTimeout, setTooltipTimeout] = useState(null);
  const [hideTimeout, setHideTimeout] = useState(null);

  const showTooltip = (e, text) => {
    const rect = e.target.getBoundingClientRect();

    // Cancela timeouts anteriores
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    if (hideTimeout) clearTimeout(hideTimeout);

    // Aguarda 3 segundos antes de exibir a tooltip
    const timeoutId = setTimeout(() => {
      setTooltip({
        visible: true,
        x: rect.left + rect.width / 2, // Centraliza no botão
        y: rect.top - 10, // Posiciona acima do botão
        text,
      });

      // Define um timeout para esconder a tooltip após 3 segundos
      const hideId = setTimeout(() => {
        setTooltip({ visible: false, x: 0, y: 0, text: '' });
      }, 3000);

      setHideTimeout(hideId);
    }, 1000);

    setTooltipTimeout(timeoutId);
  };

  const hideTooltip = () => {
    // Cancela qualquer timeout ativo
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    if (hideTimeout) clearTimeout(hideTimeout);

    setTooltip({ visible: false, x: 0, y: 0, text: '' });
  };


  return (


        <div
          className={cardData.dias_para_proxima_tarefa != null && cardData.dias_para_proxima_tarefa < 0 ? 'card-container-message' : 'card-container'}


          onClick={(e) => { viewCard(e); e.stopPropagation() }}
        // style={{ boxShadow: cardData.dias_para_proxima_tarefa != null && cardData.dias_para_proxima_tarefa < 0 ? '2px 2px 2px rgba(255, 0, 0, 0.5), 0 0 2px 1px rgba(255, 0, 0, 0.08), 0 2px 2px rgba(255, 0, 0, 0.16)' : '' }}
        >

          <div className='rotulo-container' style={{ backgroundColor: getEtiquetaColorById(cardData.etiqueta_id) }}></div>

          {/* {
            modalLoading &&
            <>
              <div className='card-loading'>{mensagemLoading}<button onClick={() => setModalLoading(false)} className='btn-close-loading-card'>x</button></div>
            </>
          } */}

          {modalLoading && (
            <div className="loading-overlay">
              <div className="card-loading">
                <button
                  onClick={() => setModalLoading(false)}
                  className="btn-close-loading-card"
                >
                  x
                </button>
                <div className="loading-spinner"></div>
                <div className="loading-message">{mensagemLoading}</div>
              </div>
            </div>
          )}

          {
            openCloseEditStatusModal && (
              <div className='edit-status-footer-container'>
                <div className='update-card-status-container'>

                  <div onMouseEnter={(e) => showTooltip(e, 'Voltar Status ao Padrão')} onMouseLeave={hideTooltip} style={{ display: user.access_level != '5' ? 'none' : '' }} onClick={(event) => updateCardStatus(cardData.card_id, null, event)} className='icon-edit-status-card-container' >
                    <Md360 style={{ color: statusCard === null || statusCard === '' ? '' : '#9c9c9c' }} className='icon-status-card-vendido' />
                  </div>
                  <div onMouseEnter={(e) => showTooltip(e, 'Alterar Status para Vendido')} onMouseLeave={hideTooltip} onClick={(event) => updateCardStatus(cardData.card_id, 'Vendido', event)} className='icon-edit-status-card-container' >
                    <MdThumbUp style={{ color: statusCard === 'Vendido' ? '' : '#9c9c9c' }} className='icon-status-card-vendido' />
                  </div>
                  <div onMouseEnter={(e) => showTooltip(e, 'Alterar Status para Perdido')} onMouseLeave={hideTooltip} onClick={(event) => updateCardStatusPerdido(cardData.card_id, 'Perdido', event)} className='icon-edit-status-card-container' >
                    <MdThumbDown style={{ color: statusCard === 'Perdido' ? '' : '#9c9c9c' }} className='icon-status-card-perdido' />
                  </div>

                  <button onClick={(e) => {
                    setOpenCloseEditStatusModal(false);
                    e.stopPropagation();
                  }} className='btn-close-select-status'>x</button>

                </div>
              </div>
            )
          }
          <div className='card-header'>

            <label onMouseEnter={(e) => showTooltip(e, 'Nome do Cliente')} onMouseLeave={hideTooltip} className='card-title'>{cardData.name.toUpperCase().substring(0, 26)}</label>

            <MdShare onMouseEnter={(e) => showTooltip(e, 'Compartilhar este Card via Chat Interno com alguém da equipe')} onMouseLeave={hideTooltip} className='icons-shared-card' onClick={(event) => shareCard(cardData.card_id, event)} />

          </div>
          <div className='card-body'>

            {
              showCard && (
                <>
                  <label className='card-body-item'>
                    <MdBookmark className='card-icon-item' />
                    Etiqueta:
                    <select
                      onMouseEnter={(e) => showTooltip(e, 'Selecionar Etiqueta')} onMouseLeave={hideTooltip}
                      className="select-etiqueta-card"
                      value={cardData.etiqueta_id ? cardData.etiqueta_id : null}
                      onChange={(e) => {
                        setSelectedEtiquetaId(e.target.value);
                        updateCardEtiqueta(e.target.value);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      disabled={!getAccessLevel('etiqueta')}
                    >
                      <option value={0}>Sem Etiqueta</option>
                      {sortedEtiquetas.map(etiqueta => (
                        <option
                          style={{ /*backgroundColor: getEtiquetaColorById(etiqueta.id)*/ }}
                          key={etiqueta.id}
                          value={etiqueta.id}
                        >
                          <label className='item-select-etiqueta-card'>{etiqueta.description}</label>
                        </option>
                      ))}
                    </select>
                  </label>








                  <label className='card-body-item-column'>
                    <MdViewColumn className='card-icon-item' />
                    Coluna:
                    <select
                      onMouseEnter={(e) => showTooltip(e, 'Alterar a Coluna que o Card se encontra')} onMouseLeave={hideTooltip}
                      className="select-column-card"
                      value={Number(selectedColumnId)} // Assegura que o valor seja um número
                      onChange={(e) => {
                        if (!blockColumnCard) {
                          setSelectedColumnId(Number(e.target.value)); // Converte para número ao atualizar o estado
                          updateCardColumn(Number(e.target.value)); // Garante que passe como número
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      disabled={blockColumnCard || !getAccessLevel('coluna')} // Adiciona o atributo disabled
                    >
                      {canEditCurrentColumn
                        ? columnsUser
                          .filter(column => editableColumnIds.includes(column.id)) // Filtro aplicado aqui
                          .map(column => (
                            <option key={column.id} value={column.id}>
                              {column.name}
                            </option>
                          ))
                        : (
                          <option >
                            {getNameColumnCard(cardData.column_id)}
                          </option>
                        )
                      }
                    </select>

                    {blockColumnCard ? (
                      <MdLockOutline
                        onMouseEnter={(e) => showTooltip(e, 'Desbloquear Card')} onMouseLeave={hideTooltip}
                        style={{ backgroundColor: 'red' }}
                        className='lock-column'
                        onClick={(e) => {
                          updateBlockColumnCard(false);
                          e.stopPropagation();
                        }}
                      />
                    ) : (
                      <MdLockOpen
                        onMouseEnter={(e) => showTooltip(e, 'Bloquear Card nesta Coluna')} onMouseLeave={hideTooltip}
                        className='lock-column'
                        onClick={(e) => {
                          updateBlockColumnCard(true);
                          e.stopPropagation();
                        }}
                      />
                    )}

                  </label>











                  <label className='row-column-container'>

                    <label onMouseEnter={(e) => showTooltip(e, 'Número do orçamento')} onMouseLeave={hideTooltip} className='card-body-item-chat-bot'>
                      <MdArticle className='card-icon-item' />

                      <label style={{ marginLeft: '0px', fontWeight: '500' }} >{cardData.document_number ? cardData.document_number.substring(0, 35) : 'Não informado'}</label>
                    </label>
                    <MdDeleteForever
                      onMouseEnter={(e) => showTooltip(e, 'Excluir Card de forma Permanente')} onMouseLeave={hideTooltip}
                      className='btn-delete-card'

                      onClick={(e) => {
                        excluirCard();
                        e.stopPropagation();
                      }}
                    />

                  </label>


                  <label style={{ display: 'none' }} className='card-body-item'>
                    <MdAssignment className='card-icon-item' />Orçamento: {cardData.second_document_number ? cardData.second_document_number.substring(0, 35) : 'Não Informado'}
                  </label>
                  <label className='card-body-item'>
                    <MdAssignmentTurnedIn className='card-icon-item' />

                    <label onMouseEnter={(e) => showTooltip(e, 'Número do Pedido')} onMouseLeave={hideTooltip} style={{ marginLeft: '0px', fontWeight: '500' }} >{cardData.pedido_number ? cardData.pedido_number.substring(0, 35) : 'Não Informado'}</label>
                  </label>

                  <label onMouseEnter={(e) => showTooltip(e, 'Nome da Obra')} onMouseLeave={hideTooltip} className='card-body-item'>
                    <MdHome className='card-icon-item' />

                    <label style={{ marginLeft: '0px', fontWeight: '500' }} >{cardData.nome_obra && cardData.nome_obra != '' ? cardData.nome_obra.substring(0, 35) : 'Não informado'}</label>
                  </label>



                  {getAccessLevel('contato') &&
                    <label onMouseEnter={(e) => showTooltip(e, 'Telefone')} onMouseLeave={hideTooltip} className='card-body-item-fone'>
                      <div className='card-body-item-fone-number'>
                        <MdWhatsapp className='icons-whatsapp' onClick={() => abrirWhatsApp(cardData.fone)} />

                        <label style={{ marginLeft: '0px', fontWeight: '500' }} >{cardData.fone ? cardData.fone : 'Não informado'}</label>
                      </div>
                    </label>
                  }

                  {getAccessLevel('contato') &&
                    <label onMouseEnter={(e) => showTooltip(e, 'Email')} onMouseLeave={hideTooltip} className='card-body-item'>
                      <MdAlternateEmail className='card-icon-item' />

                      <label style={{ marginLeft: '0px', fontWeight: '500' }} >{getAccessLevel('contato') && cardData.email ? cardData.email.substring(0, 35) : "Não informado"}</label>
                    </label>
                  }



                  <label className='card-body-item'>
                    <MdRoom className='card-icon-item' />

                    <label onMouseEnter={(e) => showTooltip(e, 'Cidade / Estado')} onMouseLeave={hideTooltip} style={{ marginLeft: '0px', fontWeight: '500' }} >{(cardData.city ? cardData.city : 'Cidade') + '/' + (cardData.state ? cardData.state : 'UF')} </label>
                  </label>


                  <label className='card-body-item'>
                    <MdAccountBalance className='card-icon-item' />

                    <label onMouseEnter={(e) => showTooltip(e, 'Valor de custo')} onMouseLeave={hideTooltip} className='card-valor-item'>{getAccessLevel('valor') && cardData.cost_value ? parseFloat(cardData.cost_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</label>
                  </label>
                  <label className='card-body-item'>
                    <MdShoppingCart className='card-icon-item' />

                    <label onMouseEnter={(e) => showTooltip(e, 'Valor Final de Venda')} onMouseLeave={hideTooltip} className='card-valor-item'>{getAccessLevel('valor') && cardData.sale_value ? parseFloat(cardData.sale_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</label>
                  </label>


                  <label className='card-body-item'>
                    <MdAccountBox className='card-icon-item' />

                    <label onMouseEnter={(e) => showTooltip(e, 'Entidade Responsável')} onMouseLeave={hideTooltip} style={{ marginLeft: '0px', fontWeight: '500' }} >{getUsernameById(cardData.entity_id).substring(0, 28)}</label>
                  </label>


                  <label style={{ display: 'none' }} className='card-body-item'>
                    <MdCreate className='card-icon-item' />{formatDate(cardData.created_at)}
                  </label>
                  <label style={{ display: 'none' }} className='card-body-item'>
                    <MdOutlineUpdate className='card-icon-item' />{formatDate(cardData.updated_at)}
                  </label>
                  <label style={{ display: 'none' }} className='card-body-item'>
                    <MdAnalytics className='card-icon-item' />{cardData.status_date ? formatDate(cardData.status_date) : ''}
                  </label>









                  <label onMouseEnter={(e) => showTooltip(e, 'Tipo de Produto')} onMouseLeave={hideTooltip} className='card-body-item'>
                    <MdAddShoppingCart className='card-icon-item' />

                    <label style={{ marginLeft: '0px', fontWeight: '500' }} >{cardData.produto ? cardData.produto : 'Não informado'}</label>
                  </label>




                  <label className='row-column-container'>

                    <label className='card-body-item-chat-bot'>
                      <MdAdsClick className='card-icon-item' />

                      <label onMouseEnter={(e) => showTooltip(e, 'Origem')} onMouseLeave={hideTooltip} style={{ marginLeft: '0px', fontWeight: '500' }} >{cardData.origem ? cardData.origem : 'Não informado'}</label>
                    </label>

                    <MdOutlineSendToMobile
                      className='lock-column' style={{ display: user.access_level === 5 || user.user_type === 'Supervisor Comercial' ? '' : 'none' }}

                      onClick={(e) => {
                        enviarMensagemPotencialCliente();
                        e.stopPropagation();
                      }}
                    />

                  </label>



                  <label className='row-column-container'>

                    <label onMouseEnter={(e) => showTooltip(e, 'Criar uma etapa de produção')} onMouseLeave={hideTooltip} className='card-body-item-chat-bot'>
                      <MdAutoAwesomeMotion className='card-icon-item' />
                      Etapa de Produção:
                      <label style={{ marginLeft: '5px', fontWeight: '700' }} >{cardData.etapa_producao === 0 ? 1 : cardData.etapa_producao + 1}</label>
                    </label>
                    <MdLibraryAdd
                      className='lock-column'

                      onClick={(e) => {
                        criarEtapaDeProducao();
                        e.stopPropagation();
                      }}
                    />

                  </label>





                  <label style={{ display: 'none' }} className='row-column-container'>

                    <label className='card-body-item-chat-bot'>
                      <MdCreditCard className='card-icon-item' />ID: {cardData.card_id}
                    </label>
                    <MdDeleteForever
                      className='btn-delete-card'

                      onClick={(e) => {
                        excluirCard();
                        e.stopPropagation();
                      }}
                    />

                  </label>






                  <div className='modulos-card-container'>


                    <div className='modulos-card-column-container'>

                    </div>
                    <label className='label-modulos-title'>
                      Módulos
                    </label>
                    <div className='btns-card-container'>

                      {getAccessLevel('editar') &&
                        <button onMouseEnter={(e) => showTooltip(e, 'Editar Card')} onMouseLeave={hideTooltip} onClick={(e) => getCardData(e)} className='btn-update-card'>
                          <MdEdit className='icons-btns-update-card' />
                        </button>
                      }

                      {getAccessLevel('historico') &&
                        <button onMouseEnter={(e) => showTooltip(e, 'Histórico Geral')} onMouseLeave={hideTooltip} onClick={openHistoricModal} className='btn-update-card'>
                          <MdOutlineHistory className='icons-btns-update-card' />
                        </button>
                      }

                      {getAccessLevel('tarefas') &&
                        <button onMouseEnter={(e) => showTooltip(e, 'Criar / Editar Tarefas')} onMouseLeave={hideTooltip} onClick={openTarefasModal} className='btn-update-card'>
                          <GrTask className='icons-btns-update-card' />
                        </button>
                      }

                      {getAccessLevel('compartilhar') &&
                        <button onMouseEnter={(e) => showTooltip(e, 'Compartilhar este Card com outros usuários da empresa')} onMouseLeave={hideTooltip} onClick={openCompartilharModal} className='btn-update-card'>
                          <MdShare className='icons-btns-update-card' />
                        </button>
                      }





                    </div>





                    <label style={{ display: '' }} className='label-modulos-title'>
                      Módulos Extras
                    </label>
                    <div style={{ display: '' }} className='btns-card-container'>



                      {getAccessLevel('producao') &&
                        <button onMouseEnter={(e) => showTooltip(e, 'Processo de Produção')} onMouseLeave={hideTooltip} onClick={openModuloEsquadriasModal} className='btn-update-card'>
                          <MdWindow className='icons-btns-update-card' />
                        </button>
                      }

                      {getAccessLevel('anexos') &&
                        <button onMouseEnter={(e) => showTooltip(e, 'Arquivos Anexados')} onMouseLeave={hideTooltip} onClick={openAnexosModal} className='btn-update-card'>
                          <MdAttachFile className='icons-btns-update-card' />
                        </button>
                      }

                      {false &&
                        <button onClick={(e) => { handleEmailButtonClick(); e.stopPropagation(); }} className='btn-update-card'>
                          <MdEmail className='icons-btns-update-card' />
                          {showEmails && <EmailConversation clientEmail={cardData.email} />}
                        </button>

                      }

                      {true && (
                        <button onMouseEnter={(e) => showTooltip(e, 'Formulário de Pedidos')} onMouseLeave={hideTooltip} onClick={(e) => openClosePedidos(e)} className='btn-update-card'>
                          <MdAssignment className='icons-btns-update-card' />

                        </button>
                      )}

                      {false && (
                        <button onClick={(e) => { handleCustomModuleButtonClick(e); e.stopPropagation(); }} className='btn-update-card'>
                          <MdEdit className='icons-btns-update-card' />
                        </button>
                      )}


                      <button onMouseEnter={(e) => showTooltip(e, 'Gera um link para o cliente acompanhar o andamento da obra')} onMouseLeave={hideTooltip} onClick={copyTrackingLink} className='btn-update-card'>
                        <MdLink className='icons-btns-update-card' />
                      </button>

                      {linkCopied && <p className="link-copied-message">Link copiado para a área de transferência!</p>}





                      <button
                        onMouseEnter={(e) => showTooltip(e, 'Módulo Faktory - Testes')} onMouseLeave={hideTooltip}
                        onClick={(e) => {
                          openFaktoryModule(cardData);
                          e.stopPropagation(); // Chama o método stopPropagation do evento 'e'
                        }}
                        className='btn-update-card-faktory'
                      >
                        <img src={LogoFaktory} alt="Logo Faktory" className='logo-faktory-module' />
                      </button>







                      {Array.isArray(modules) && modules.map((module, index) => (

                        <button style={{ display: 'none' }} key={index} onClick={(e) => { handleCustomModuleButtonClick(e, module); e.stopPropagation(); }} className='btn-update-card'>
                          <MdEdit className='icons-btns-update-card' />
                        </button>
                      ))}



                      {showNewModuleContainer ? (
                        <div className='create-new-module-container'>
                          <input
                            type="text"
                            value={newModuleName}
                            onChange={(e) => setNewModuleName(e.target.value)}
                            placeholder="Nome do Módulo"
                            onClick={(e) => { e.stopPropagation(); }}
                            className='create-new-module-input'
                          />

                          <div className='create-new-module-btns-container'>

                            <button className='btn-create-new-module-cancel' onClick={(e) => { e.stopPropagation(); setShowNewModuleContainer(false) }}>Cancelar</button>
                            <button className='btn-create-new-module-create' onClick={(e) => { e.stopPropagation(); createNewModule() }}>Criar Módulo</button>


                          </div>

                        </div>
                      ) : (
                        <div style={{ display: 'none' }} className='btn-create-new-module-container'>
                          <button className='btn-create-new-module' onClick={(e) => { e.stopPropagation(); setShowNewModuleContainer(true) }}>+</button>
                        </div>
                      )}




                    </div>




                    <label style={{ display: '' }} className='label-modulos-title'>
                      Marketing
                    </label>

                    <div onMouseEnter={(e) => showTooltip(e, 'Define a Temperatura da Negociação')} onMouseLeave={hideTooltip} style={{ display: '' }} className='btns-card-container'>

                      <div className='temperatura-container'>
                        <button onClick={(e) => { e.stopPropagation(); updateTemperaturaCard(1) }} className='btn-temperatura-frio' style={{ height: cardData.temperatura_card === 1 ? '30px' : '' }}>Frio</button>
                        <button onClick={(e) => { e.stopPropagation(); updateTemperaturaCard(2) }} className='btn-temperatura-morno' style={{ height: cardData.temperatura_card === 2 ? '30px' : '' }}>Morno</button>
                        <button onClick={(e) => { e.stopPropagation(); updateTemperaturaCard(3) }} className='btn-temperatura-quente' style={{ height: cardData.temperatura_card === 3 ? '30px' : '' }}>Quente</button>
                      </div>

                    </div>





                    <label style={{ display: cardData.status === 'Perdido' ? '' : 'none' }} className='label-modulos-title'>
                      Motivo da perda
                    </label>


                    <label style={{ display: cardData.status === 'Perdido' ? '' : 'none' }} className='card-body-item'>

                      <label className='motivo-perda-card-container' style={{ marginLeft: '0px', fontWeight: '500' }} >                      {(cardData.motivo_venda_perdida ? cardData.motivo_venda_perdida : '')}</label>
                    </label>



                    <label style={{ display: cardData.status === 'Arquivado' ? '' : 'none' }} className='label-modulos-title'>
                      Motivo por Arquivar
                    </label>


                    <label style={{ display: cardData.status === 'Arquivado' ? '' : 'none' }} className='card-body-item-arquivado'>

                      <label className='motivo-arquivado-card-container' style={{ marginLeft: '0px', fontWeight: '500' }} >
                        {(cardData.motivo_card_arquivado ? cardData.motivo_card_arquivado : '')}
                      </label>

                    </label>





                  </div>







                </>
              )
            }

            {
              true && !showCard && (
                <>
                  <label className='card-body-item-separate-container'>


                    {getAccessLevel('valor') &&
                      <label className='card-body-item-separate-value'>
                        <label onMouseEnter={(e) => showTooltip(e, 'Valor de Custo')} onMouseLeave={hideTooltip} style={{ fontSize: '15px' }} className='card-valor-item'>
                          {cardData.cost_value ? parseFloat(cardData.cost_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '0,00'}
                        </label>
                      </label>
                    }



                    {!getAccessLevel('valor') &&
                      <label onMouseEnter={(e) => showTooltip(e, 'Valor de Custo')} onMouseLeave={hideTooltip} className='card-body-item-separate-value'>
                        <label style={{ fontSize: '15px' }} className='card-valor-item'>
                          {'R$ ******'}
                        </label>
                      </label>
                    }


                    <label onMouseEnter={(e) => showTooltip(e, 'Cidade / Estado')} onMouseLeave={hideTooltip} className='card-body-item-separate'>
                      {(cardData.city ? cardData.city.substring(0, 20) : 'Cidade') + '/' + (cardData.state ? cardData.state.substring(0, 20) : 'Estado')}
                    </label>

                    <label onMouseEnter={(e) => showTooltip(e, 'Número Orçamento / Pedido')} onMouseLeave={hideTooltip} className='card-body-item-separate'>
                      {
                        (cardData.document_number ? cardData.document_number.substring(0, 18) : '') +
                        (cardData.document_number && cardData.pedido_number ? ' / ' : '') +
                        (cardData.pedido_number ? cardData.pedido_number.substring(0, 18) : '') +
                        (!cardData.document_number && !cardData.pedido_number ? 'Sem Orçamento' : '')
                      }
                    </label>

                    <label onMouseEnter={(e) => showTooltip(e, 'Etidade / Responsável')} onMouseLeave={hideTooltip} style={{ fontSize: '12px', marginTop: '2px' }}>
                      {getUsernameById(cardData.entity_id)?.substring(0, 15)}
                      {cardData.responsavel ? ` / ${cardData.responsavel.substring(0, 15).toUpperCase()}` : ''}
                    </label>


                    <label style={{ display: 'none', marginTop: '20px' }} className='card-id-item-separate'>ID: {cardData.card_id}</label>




                  </label>
                </>
              )
            }

          </div>
          <div className='card-footer'>

            <div className='card-icons-status-container'>
              <button
                onMouseEnter={(e) => showTooltip(e, 'Alterar o status para Vendido ou Perdido')} onMouseLeave={hideTooltip}
                style={{ display: statusCard === null || statusCard === '' || statusCard === ' ' || statusCard === undefined || statusCard === 'Novo' ? '' : 'none' }} onClick={(event) => openCloseEditEstatusCard(event)} className='btn-open-status-container'>
                <MdThumbUp />
                <MdThumbDown />

              </button>


              {/* <Md360 onClick={(event) => openCloseEditEstatusCard(event)} style={{ display: statusCard === null || statusCard === '' ? '' : 'none' }} className='card-icon-em-andamento' /> */}
              <MdThumbUp onMouseEnter={(e) => showTooltip(e, 'Card marcado como Vendido!')} onMouseLeave={hideTooltip} onClick={(event) => openCloseEditEstatusCard(event)} style={{ display: statusCard === 'Vendido' ? '' : 'none' }} className='card-icon-vendido' />
              <MdThumbDown onMouseEnter={(e) => showTooltip(e, 'Card marcado como Perdido!')} onMouseLeave={hideTooltip} onClick={(event) => openCloseEditEstatusCard(event)} style={{ display: statusCard === 'Perdido' ? '' : 'none' }} className='card-icon-perdido' />
              <MdFolder onMouseEnter={(e) => showTooltip(e, 'Card marcado como Arquivado!')} onMouseLeave={hideTooltip} onClick={(event) => openCloseEditEstatusCard(event)} style={{ display: statusCard === 'Arquivado' ? '' : 'none', color: '#82839E' }} className='card-icon-vendido' />
              <MdThumbUp onClick={(event) => openCloseEditEstatusCard(event)} style={{ display: statusCard === 'InstalacaoExt' ? '' : 'none' }} className='card-icon-vendido' />
              <MdThumbUp onClick={(event) => openCloseEditEstatusCard(event)} style={{ display: statusCard === 'Assistencia' ? '' : 'none' }} className='card-icon-vendido' />
              <MdThumbUp onClick={(event) => openCloseEditEstatusCard(event)} style={{ display: statusCard === 'AssistenciaExt' ? '' : 'none' }} className='card-icon-vendido' />
              <label style={{ backgroundColor: cardData.etapa_producao + 1 > 1 ? '' : 'transparent' }} className='etapa-producao'>Parte: {cardData.etapa_producao + 1}</label>



            </div>

            {/* <label style={{ backgroundColor: getBackgroundColor(getDaysSinceUpdate(cardData.updated_at)) }} className='card-n-dias'>{getDaysSinceUpdate(cardData.updated_at)}</label> */}

            {/* <label className='card-n-dias'>{getDaysSinceUpdate(cardData.updated_at)}</label> */}



            {true &&
              <label className='card-star-container'>
                <MdGrade
                  onMouseEnter={(e) => showTooltip(e, 'Alterar o Potencial da Negociação!')} onMouseLeave={hideTooltip}
                  onClick={(event) => updatePotencialVenda(event, 1)}
                  className='card-icon-star'
                  style={{ color: getAccessLevel('estrelas') && (potencialVenda > 1 || potencialVenda === 1) ? 'gold' : '' }}
                />
                <MdGrade
                  onMouseEnter={(e) => showTooltip(e, 'Alterar o Potencial da Negociação!')} onMouseLeave={hideTooltip}
                  onClick={(event) => updatePotencialVenda(event, 2)}
                  className='card-icon-star'
                  style={{ color: getAccessLevel('estrelas') && (potencialVenda > 2 || potencialVenda === 2) ? 'gold' : '' }}
                />
                <MdGrade
                  onMouseEnter={(e) => showTooltip(e, 'Alterar o Potencial da Negociação!')} onMouseLeave={hideTooltip}
                  onClick={(event) => updatePotencialVenda(event, 3)}
                  className='card-icon-star'
                  style={{ color: getAccessLevel('estrelas') && (potencialVenda > 3 || potencialVenda === 3) ? 'gold' : '' }}
                />
                <MdGrade
                  onMouseEnter={(e) => showTooltip(e, 'Alterar o Potencial da Negociação!')} onMouseLeave={hideTooltip}
                  onClick={(event) => updatePotencialVenda(event, 4)}
                  className='card-icon-star'
                  style={{ color: getAccessLevel('estrelas') && (potencialVenda > 4 || potencialVenda === 4) ? 'gold' : '' }}
                />
                <MdGrade
                  onMouseEnter={(e) => showTooltip(e, 'Alterar o Potencial da Negociação!')} onMouseLeave={hideTooltip}
                  onClick={(event) => updatePotencialVenda(event, 5)}
                  className='card-icon-star'
                  style={{ color: getAccessLevel('estrelas') && (potencialVenda > 5 || potencialVenda === 5) ? 'gold' : '' }}
                />
              </label>
            }







            <FaBell
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => openTarefasModal(e)}
              style={{ display: cardData?.dias_para_proxima_tarefa != null ? '' : 'none', backgroundColor: cardData?.dias_para_proxima_tarefa != null ? 'red' : '' }}
              className='icons-task-card-mini'
            />



            <label style={{ display: 'none', backgroundColor: getBackgroundColor(getDaysSinceUpdate(cardData.updated_at)) }} className='card-n-dias'>{cardData.total_tarefas_pendentes}</label>


            <label style={{ display: 'none', backgroundColor: getBackgroundColor(getDaysSinceUpdate(cardData.updated_at)) }} className='card-n-dias'>{cardData.dias_para_proxima_tarefa}</label>


            <label onMouseEnter={(e) => showTooltip(e, cardData.status != '' ? 'Indica quantos dias este Card está parado neste Processo!' : 'Indica quantos dias este Card está sem atualização!')} onMouseLeave={hideTooltip} style={{ display: '', backgroundColor: getBackgroundColor(getDaysSinceUpdate(cardData.updated_at)) }} className='card-n-dias'>{getDaysSinceUpdate(cardData.updated_at)}</label>
            <label style={{ display: 'none', backgroundColor: getBackgroundColor(getDaysSinceUpdate(cardData.updated_at)) }} className='card-n-dias-expanded'>{`${getDaysSinceUpdate(cardData.updated_at)} dias sem atualização `}</label>

            {isHovered && (

              <div className='info-tarefas-conatiner-card'>


                <label style={{ display: 'none' }} className='card-n-dias-tarefas'>Total de tarefas: {cardData.total_tarefas_pendentes}</label>


                <label style={{ display: '' }} className='card-n-dias-tarefas'>Dias para a próxima tarefa: {cardData.dias_para_proxima_tarefa}</label>




              </div>
            )
            }


          </div>
          {
            openCloseModalVendaPerdida &&
            <VendaPerdida cardData={cardData} closeModal={closeVendaPerdidaModal} />
          }




          {/* Tooltip com posição fixa */}
          {tooltip.visible && (
            <div
              style={{
                position: 'fixed', // Fixado à tela
                top: tooltip.y,
                left: tooltip.x,
                transform: 'translate(-50%, -100%)', // Centraliza horizontalmente e posiciona acima
                background: 'black',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                zIndex: 1000,
              }}
            >
              {tooltip.text}
            </div>
          )}


        </div>


  );
}

export default PreviewCard;

