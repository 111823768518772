import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library';
import axios from 'axios';
import './style.css';

import { MdAutorenew } from "react-icons/md";
import { RiBarcodeBoxLine } from "react-icons/ri";
import { MdOutlineSearch, MdArrowBack } from "react-icons/md";

import { fileApiUrl } from '../../../config/apiConfig';

function FaktoryPage() {

  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [variant, setVariant] = useState('');
  const [quantity, setQuantity] = useState('');
  const [productInfo, setProductInfo] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [facingMode, setFacingMode] = useState('environment');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const videoRef = useRef(null);
  const codeReaderRef = useRef(null);
  const hasScannedRef = useRef(false);

  useEffect(() => {
    setProductInfo(null);
    setQuantity('');
  }, [code, variant]);

  useEffect(() => {
    if (code && isScanned && !isProcessing) {
      fetchProduct();
      setIsScanned(false);
    }
  }, [code, variant, isScanned]);

  const startScanner = async () => {
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.QR_CODE]);

    const codeReader = new BrowserMultiFormatReader(hints);
    codeReaderRef.current = codeReader;
    hasScannedRef.current = false;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode,
          width: { ideal: 640 },
          height: { ideal: 640 }
        }
      });

      const video = videoRef.current;
      video.srcObject = stream;

      await new Promise((resolve) => {
        video.onloadedmetadata = () => {
          video.play();
        };
        video.oncanplay = () => {
          // Espera 300ms para estabilizar a imagem antes de iniciar leitura
          setTimeout(resolve, 300);
        };
      });

      console.log('Iniciando leitura contínua com decodeFromVideoDevice...');

      codeReader.decodeFromVideoDevice(null, video, (result, err) => {
        if (result && !hasScannedRef.current) {
          hasScannedRef.current = true;

          const scannedData = result.getText();
          const [scannedCode, scannedVariant] = scannedData.split('|');

          console.log('QR code detectado:', scannedData);

          codeReader.reset();
          stopCamera();

          setCode(scannedCode || '');
          setVariant(scannedVariant || '');
          setIsScanned(true);
        }

        // Ignora erros de leitura comuns
        if (err && err.name !== 'NotFoundException' && err.name !== 'ChecksumException' && err.name !== 'FormatException') {
          console.error('Erro ao escanear:', err);
          // NÃO para a câmera, só loga
        }
      });


    } catch (err) {
      console.error('Erro ao acessar a câmera:', err);
      alert(`Erro ao acessar a câmera: ${err.message}`);
      stopCamera();
    }
  };






  const stopCamera = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    if (codeReaderRef.current) {
      codeReaderRef.current.reset();
    }
    setScanning(false);
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'environment' ? 'user' : 'environment'));
  };

  const fetchProduct = async () => {
    if (!code) {
      alert('Por favor, informe ou escaneie um código antes de buscar');
      return;
    }

    const fullCode = variant ? `${code}|${variant}` : code;
    setIsProcessing(true);
    try {
      const response = await axios.get(`${fileApiUrl}/faktory/stock/product/${fullCode}`);
      console.log('Resposta da API:', response.data);
      if (response.status === 200) {
        const product = response.data;
        setProductInfo({
          code: product.code,
          name: product.name || 'Não informado',
          variant: product.variant || 'Não informado',
          group: product.group || 'Não informado',
          quantity: product.quantity || 0,
          idProduto: product.ID_PRODUTO,
          idProdutoVariante: product.ID_PRODUTOVARIANTE,
          unidade: product.unidade,
        });
      }
    } catch (error) {
      console.error('Erro ao buscar o produto:', error);
      alert('Produto não encontrado ou erro ao buscar no estoque');
      setQuantity('');
      setProductInfo(null);
    } finally {
      setIsProcessing(false);
    }
  };

  // const handleCodeSubmit = async (tipo) => {
  //   const enteredQuantity = parseInt(quantity, 10);
  //   const stockQuantity = productInfo?.quantity || 0;

  //   if (isNaN(enteredQuantity) || enteredQuantity <= 0) {
  //     alert('Por favor, insira uma quantidade válida maior que 0.');
  //     return;
  //   }

  //   if (enteredQuantity > stockQuantity) {
  //     alert(`Quantidade informada (${enteredQuantity}) é maior que a quantidade em estoque (${stockQuantity}).`);
  //     return;
  //   }

  //   setIsProcessing(true);
  //   try {
  //     const payload = {
  //       tpEntradaSaida: tipo,
  //       idProduto: productInfo.idProduto,
  //       idProdutoVariante: productInfo.idProdutoVariante,
  //       quantity: enteredQuantity,
  //     };
  //     const response = await axios.post(`${fileApiUrl}/faktory/stock/update-quantity`, payload);
  //     if (response.status === 200 || response.status === 201) {
  //       alert('Estoque atualizado com sucesso!');
  //       setQuantity('');
  //       fetchProduct();
  //     }
  //   } catch (error) {
  //     console.error('Erro ao atualizar o estoque:', error);
  //     alert(`Erro ao atualizar o estoque: ${error.response?.data?.message || error.message}`);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };


  const handleCodeSubmit = async (tipo) => {
    const enteredQuantity = parseInt(quantity, 10);
    const stockQuantity = productInfo?.quantity || 0;

    if (isNaN(enteredQuantity) || enteredQuantity <= 0) {
      alert('Por favor, insira uma quantidade válida maior que 0.');
      return;
    }

    if (enteredQuantity > stockQuantity && tipo === 1) {
      alert(`Quantidade informada (${enteredQuantity}) é maior que a quantidade em estoque (${stockQuantity}).`);
      return;
    }

    const operacao = tipo === 0 ? 'entrada' : 'saída';

    const unidadeFormatada =
      productInfo.unidade === 'BR' ? 'Barras' :
        productInfo.unidade === 'UN' ? 'Unidades' :
          productInfo.unidade === 'PC' ? 'Peças' :
            productInfo.unidade === 'M' ? 'Metros' :
              `${productInfo.unidade} unidades`;

    const confirmacao = window.confirm(
      `Deseja realmente realizar a ${operacao} de ${enteredQuantity} ${unidadeFormatada} manualmente no estoque?`
    );


    if (!confirmacao) return;

    setIsProcessing(true);
    try {
      const payload = {
        tpEntradaSaida: tipo,
        idProduto: productInfo.idProduto,
        idProdutoVariante: productInfo.idProdutoVariante,
        quantity: enteredQuantity,
      };
      const response = await axios.post(`${fileApiUrl}/faktory/stock/update-quantity`, payload);
      if (response.status === 200 || response.status === 201) {
        alert('Estoque atualizado com sucesso!');
        setQuantity('');
        fetchProduct();
      }
    } catch (error) {
      console.error('Erro ao atualizar o estoque:', error);
      alert(`Erro ao atualizar o estoque: ${error.response?.data?.message || error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };







  const handleCodeChange = (e) => {
    setCode(e.target.value);
    setIsScanned(false);
  };

  const handleVariantChange = (e) => {
    setVariant(e.target.value);
    setIsScanned(false);
  };

  useEffect(() => {
    if (scanning) {
      startScanner();
    }
  }, [scanning, facingMode]);





  useEffect(() => {
    let touchStartY = 0;

    const handleTouchStart = (e) => {
      touchStartY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      const currentY = e.touches[0].clientY;
      const diff = currentY - touchStartY;

      if (diff > 10 && window.scrollY === 0) {
        e.preventDefault(); // bloqueia o pull-to-refresh
      }
    };

    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);


  return (
    <div className="FaktoryPage-container">
      <header className="header-container-landing-page">
        <div className="header-logo-center-landing-page">
          <label className="header-logo-center-label-landing-page">Syncs</label>
        </div>
        <div className="header-menu-right-landing-page">
          <button className="btn-voltar-header-faktory" onClick={() => navigate('/terminal')}>
            <MdArrowBack className="icon-voltar-header-faktory" />
          </button>
        </div>
      </header>

      <div className="FaktoryPage-content">
        <h3 className="FaktoryPage-title">Localizar Produto:</h3>

        <div className="FaktoryPage-input-container">
          <div className="FaktoryPage-input-group">
            <label className="FaktoryPage-input-label" htmlFor="codigo">Código</label>
            <input
              id="codigo"
              className="FaktoryPage-input"
              type="text"
              value={code}
              onChange={handleCodeChange}
              placeholder="Inserir..."
              disabled={isProcessing}
            />
          </div>

          <div className="FaktoryPage-input-group">
            <label className="FaktoryPage-input-label" htmlFor="variante">Variante</label>
            <input
              id="variante"
              className="FaktoryPage-input"
              type="text"
              value={variant}
              onChange={handleVariantChange}
              placeholder="Inserir..."
              disabled={isProcessing}
            />
          </div>

          <div className="FaktoryPage-buttons-container">
            <button
              className="FaktoryPage-scan-button"
              onClick={fetchProduct}
              disabled={isProcessing}
            >
              <MdOutlineSearch className="FaktoryPage-icon" />
            </button>

            <button
              className="FaktoryPage-scan-button-scan"
              onClick={() => setScanning(true)}
              disabled={isProcessing}
            >
              <RiBarcodeBoxLine className="FaktoryPage-icon-scan" />
            </button>
          </div>
        </div>

        {productInfo && (
          <div className="FaktoryPage-input-container">
            <div className="FaktoryPage-input-group">
              <div className="FaktoryPage-product-info">
                <h4>Informações do Produto</h4>
                <p><strong>Código:</strong> {productInfo.code}</p>
                <p><strong>Nome:</strong> {productInfo.name}</p>
                <p><strong>Variante:</strong> {productInfo.variant}</p>
                <p><strong>Grupo:</strong> {productInfo.group}</p>
                <p><strong>Quantidade em Estoque:</strong> {productInfo.quantity}<strong>    {productInfo.unidade === 'BR' ? 'Barras' : productInfo.unidade === 'UN' ? 'Unidades' : productInfo.unidade === 'PC' ? 'Peças' : productInfo.unidade === 'M' ? 'Metros' : productInfo.unidade}</strong></p>
              </div>


            </div>


            <h3 className="FaktoryPage-title">Ajustar Estoque:</h3>

            <input
              id="quantidade"
              className="FaktoryPage-input"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              placeholder="Quantidade..."
              disabled={isProcessing}
            />
            <div className="FaktoryPage-buttons-container">
              <button
                className="FaktoryPage-update-button"
                onClick={() => handleCodeSubmit(1)}
                disabled={isProcessing}
              >
                Baixar
              </button>
            </div>



            <div className="FaktoryPage-buttons-container">
              <button
                className="FaktoryPage-update-button-entrar"
                onClick={() => handleCodeSubmit(0)}
                disabled={isProcessing}
              >
                Entrar
              </button>
            </div>












          </div>
        )}

        {scanning && (
          <div className="FaktoryPage-overlay">
            <div className="FaktoryPage-scanner-container">
              <div className="FaktoryPage-video-wrapper">
                <video className="FaktoryPage-video" ref={videoRef} autoPlay playsInline />
                <div className="FaktoryPage-scan-area"></div>
              </div>
              <div className="FaktoryPage-scanner-footer">
                <button className="FaktoryPage-toggle-button" onClick={toggleCamera}>
                  <MdAutorenew style={{ fontSize: '30px' }} />
                </button>
                <button onClick={stopCamera} className="FaktoryPage-cancel-button">Cancelar</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FaktoryPage;