import React, { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import workerSrc from 'pdfjs-dist/build/pdf.worker.entry';
import 'pdfjs-dist/web/pdf_viewer.css';
import './style.css';

GlobalWorkerOptions.workerSrc = workerSrc;

function PDFViewer({ pdfUrl, initialSearchTerm, onClose }) {
  const viewerRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [isOpen, setIsOpen] = useState(true);

  const clearViewer = () => {
    if (viewerRef.current) {
      viewerRef.current.innerHTML = '';
    }
  };

  const renderPage = async (page) => {
    const viewport = page.getViewport({ scale: 1 });
    const devicePixelRatio = window.devicePixelRatio || 1;
    const scale = (viewerRef.current.clientWidth * 0.98) / viewport.width * devicePixelRatio;
    const scaledViewport = page.getViewport({ scale });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = scaledViewport.height;
    canvas.width = scaledViewport.width;

    await page.render({ canvasContext: context, viewport: scaledViewport }).promise;

    canvas.style.width = '98%';
    canvas.style.height = 'auto';

    viewerRef.current.appendChild(canvas);
    return canvas;
  };

  const renderAndSearchPages = async (pdf) => {
    clearViewer();
    const normalizedSearchTerm = searchTerm ? searchTerm.toLowerCase() : null;
    let termFound = false;

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const canvas = await renderPage(page);

      if (normalizedSearchTerm) {
        const textContent = await page.getTextContent();
        const normalizedStrings = textContent.items.map(item => item.str.toLowerCase());

        if (!termFound && normalizedStrings.some(text => text.includes(normalizedSearchTerm))) {
          canvas.scrollIntoView({ behavior: 'smooth' });
          termFound = true;
          setIsOpen(false);
        }
      }
    }

    if (!termFound && viewerRef.current.firstChild) {
      viewerRef.current.firstChild.scrollIntoView({ behavior: 'smooth' });
      setIsOpen(false);
    }
  };

  const loadAndRenderPDF = async () => {
    try {
      clearViewer();
      if (!pdfUrl || !pdfUrl.startsWith('data:application/pdf;base64,')) {
        throw new Error('URL do PDF inválida.');
      }

      const base64Data = pdfUrl.split(',')[1];
      if (!base64Data) {
        throw new Error('Dados do PDF estão vazios.');
      }

      const binaryString = window.atob(base64Data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Check if the bytes array is empty
      if (bytes.length === 0) {
        throw new Error('O arquivo PDF está vazio.');
      }

      const pdf = await getDocument({ data: bytes }).promise;
      await renderAndSearchPages(pdf);
    } catch (error) {
      console.error('Erro ao carregar o PDF:', error);
      alert('Não foi possível carregar o PDF: ' + error.message);
    }
  };

  useEffect(() => {
    loadAndRenderPDF();
  }, [pdfUrl, searchTerm]);

  useEffect(() => {
    if (initialSearchTerm) {
      setSearchTerm(initialSearchTerm);
    }
  }, [initialSearchTerm]);

  return (
    <div className="pdf-viewer-overlay">
      <div className="pdf-viewer-content">
        <button className="pdf-viewer-close" onClick={onClose}>
          X
        </button>
        <div ref={viewerRef} className="pdf-viewer-scroll" style={{ overflow: 'auto' }} />

        {/* Input de busca sobre o PDF */}
        <div className="search-overlay">
          <input
            type="text"
            placeholder="Buscar no PDF"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pdf-search-input"
          />
        </div>
      </div>

      {isOpen && (
        <div className="overlay">
          <div className="downloading-message">
            <p>Abrindo PDF...</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PDFViewer;