import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdAssignment } from 'react-icons/md';
import { FaWarehouse, FaHome } from 'react-icons/fa';
import './style.css';

function TerminalProducao() {
  const navigate = useNavigate();

  return (
    <div className="TerminalProducao-container">
      {/* Header original mantido */}
      <header className="header-container-landing-page">
        <div className="header-logo-center-landing-page">
          <label className="header-logo-center-label-landing-page">Syncs</label>
        </div>
      </header>

      <div className="TerminalProducao-buttons-area">

      <button
          className="TerminalProducao-button"
          onClick={() => navigate('/home')}
        >
          <FaHome className="TerminalProducao-icon" />
          <span className="TerminalProducao-label">Home</span>
        </button>

        <button
          className="TerminalProducao-button"
          onClick={() => navigate('/producao')}
        >
          <MdAssignment className="TerminalProducao-icon" />
          <span className="TerminalProducao-label">Ordem de Produção</span>
        </button>

        <button
          className="TerminalProducao-button"
          onClick={() => navigate('/estoque')}
        >
          <FaWarehouse className="TerminalProducao-icon" />
          <span className="TerminalProducao-label">Estoque</span>
        </button>
      </div>
    </div>
  );
}

export default TerminalProducao;
