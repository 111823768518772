import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import LoginPage from './components/pages/LoginPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import UsersPage from './components/pages/UsersPage';
import { UserProvider } from './contexts/userContext';
import { ColumnsProvider } from './contexts/columnsContext';
import { CardProvider } from './contexts/cardContext';
import ColumnsPage from './components/pages/ColumnsPage';
import PCP from './components/pages/PCP';
import DashboardPage from './components/pages/DashboardPage';
import LandingPage from './components/pages/LandingPage';
import SignUp from './components/pages/SignUp';
import ClientePage from './components/pages/ClientePage';
import OrdensProducao from './components/pages/OrdensProducao';
import MeusTestesPage from './components/pages/MeusTestesPage';
import Participantes from './components/pages/Participantes';
import TVSlider from './components/TVSlider';
import ProjetosPage from './components/pages/ProjetosPage';
import PortalPage from './components/pages/PortalPage';
import { GlobalProvider } from './contexts/GlobalContext';
import PrivateRoute from "./components/PrivateRoute"; // 🔹 Importando corretamente o componente
import FaktoryPage from './components/pages/FaktoryPage';
import TerminalProducao from './components/pages/TerminalProducao';


function App() {
  return (
    <Router> {/* 🔹 Agora o Router vem antes do GlobalProvider */}
      <GlobalProvider> {/* 🔹 Agora o useNavigate funciona corretamente */}
        <UserProvider>
          <ColumnsProvider>
            <CardProvider>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/tracking/:cardId" element={<ClientePage />} />
                <Route path="/producao" element={<OrdensProducao />} />
                <Route path="/testes" element={<MeusTestesPage />} />
                <Route path="/estoque" element={<FaktoryPage />} />
                <Route path="/terminal" element={<TerminalProducao />} />


                <Route element={<PrivateRoute />}>
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/users" element={<UsersPage />} />
                  <Route path="/process" element={<ColumnsPage />} />
                  <Route path="/pcp" element={<PCP />} />
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route path="/participantes" element={<Participantes />} />
                  <Route path="/slider" element={<TVSlider />} />
                  <Route path="/projetos" element={<ProjetosPage />} />
                  <Route path="/portal" element={<PortalPage />} />
                </Route>
              </Routes>
            </CardProvider>
          </ColumnsProvider>
        </UserProvider>
      </GlobalProvider>
    </Router> 
  );
}

export default App;