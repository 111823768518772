import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PDFViewer from '../PDFViewer';
import './style.css';
import { MdOutlineManageSearch, MdArrowBack } from "react-icons/md";

const CLIENT_ID = '329127713016-94lonn22albknrpk2p1vkr7v2h7rcep7.apps.googleusercontent.com';
const API_KEY = 'AIzaSyCXZbla9QsrjblHCb_J4mO8MaAlvtaZrA4';
const FOLDER_ID = '1ngm-juUx3a2_wVl1FYkduIEteCnkAZOA';
const SCOPES = 'https://www.googleapis.com/auth/drive.readonly';

function OrdensProducao() {

  const navigate = useNavigate();

  const [code, setCode] = useState('');
  const [tipo, setTipo] = useState('');
  const [files, setFiles] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [isGapiReady, setIsGapiReady] = useState(false);
  const [tokenClient, setTokenClient] = useState(null);

  // Carrega e inicializa os scripts do Google
  useEffect(() => {
    const loadScripts = () => {
      // Carrega o script do gapi (Google API Client Library)
      const gapiScript = document.createElement('script');
      gapiScript.src = 'https://apis.google.com/js/api.js';
      gapiScript.async = true;
      gapiScript.defer = true;
      gapiScript.onload = () => {
        // Após carregar o gapi, carrega o script do Google Identity Services
        const gsiScript = document.createElement('script');
        gsiScript.src = 'https://accounts.google.com/gsi/client';
        gsiScript.async = true;
        gsiScript.defer = true;
        gsiScript.onload = () => {
          initClient();
        };
        gsiScript.onerror = () => {
          console.error('Erro ao carregar o script do Google Identity Services');
          setIsGapiReady(false);
          alert('Falha ao carregar o script de autenticação. Tente recarregar a página.');
        };
        document.body.appendChild(gsiScript);
      };
      gapiScript.onerror = () => {
        console.error('Erro ao carregar o script do GAPI');
        setIsGapiReady(false);
        alert('Falha ao carregar o script do Google API. Tente recarregar a página.');
      };
      document.body.appendChild(gapiScript);
    };

    const initClient = () => {
      if (!window.gapi) {
        console.error('Objeto gapi não está disponível');
        setIsGapiReady(false);
        alert('Erro ao inicializar o Google API. Tente recarregar a página.');
        return;
      }

      // Inicializa o gapi.client
      window.gapi.load('client', () => {
        window.gapi.client
          .init({
            apiKey: API_KEY,
            discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
          })
          .then(() => {
            console.log('gapi.client inicializado com sucesso');
            // Inicializa o cliente de autenticação do Google Identity Services
            if (!window.google || !window.google.accounts) {
              console.error('Objeto google.accounts não está disponível');
              setIsGapiReady(false);
              alert('Erro ao carregar o sistema de autenticação. Tente recarregar a página.');
              return;
            }

            const newTokenClient = window.google.accounts.oauth2.initTokenClient({
              client_id: CLIENT_ID,
              scope: SCOPES,
              callback: (tokenResponse) => {
                if (tokenResponse.access_token) {
                  window.gapi.client.setToken({ access_token: tokenResponse.access_token });
                  setIsGapiReady(true);
                  console.log('Autenticação bem-sucedida e gapi.client inicializado com token');
                } else if (tokenResponse.error === 'popup_closed_by_user') {
                  console.warn('Popup de login fechado pelo usuário');
                  alert('O login foi cancelado. Por favor, tente novamente.');
                  setIsGapiReady(false);
                } else {
                  console.error('Erro ao obter token de acesso:', tokenResponse);
                  setIsGapiReady(false);
                  alert('Falha na autenticação. Verifique suas credenciais e tente novamente.');
                }
              },
            });
            setTokenClient(newTokenClient);
            newTokenClient.requestAccessToken();
          })
          .catch((error) => {
            console.error('Erro ao inicializar o cliente GAPI:', error);
            setIsGapiReady(false);
            alert('Erro ao inicializar o Google API. Verifique o console para mais detalhes.');
          });
      });
    };

    loadScripts();
  }, []);

  const searchFile = () => {
    if (!isGapiReady || !window.gapi.client) {
      alert('O cliente do Google API ainda não está pronto. Tente novamente em alguns segundos.');
      return;
    }

    if (!code) {
      alert('Por favor, insira um código para buscar.');
      return;
    }

    setIsSearching(true);

    window.gapi.client.drive.files
      .list({
        q: `'${FOLDER_ID}' in parents and name contains '${code}'`,
        fields: 'files(id, name)',
      })
      .then((response) => {
        const allFiles = response.result.files || [];
        const filteredFiles = allFiles.filter((file) => file.name.includes(code));
        setFiles(filteredFiles);
        setIsSearching(false);
        if (filteredFiles.length === 0) {
          alert('Nenhum arquivo encontrado com o código fornecido.');
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar o arquivo:', error);
        console.log('Detalhes do erro:', error.result?.error || error);
        setIsSearching(false);
        // Se o erro for de autenticação, solicita um novo token
        if (error.result?.error?.code === 401 || error.result?.error?.code === 403) {
          if (tokenClient) {
            alert('Autenticação necessária. Por favor, faça login novamente.');
            tokenClient.requestAccessToken();
          } else {
            alert('Erro: Cliente de autenticação não está disponível. Tente recarregar a página.');
          }
        } else {
          alert('Erro ao buscar o arquivo. Verifique o console para mais detalhes.');
        }
      });
  };

  const handleFileClick = (fileId) => {
    if (!isGapiReady || !window.gapi.client) {
      alert('O cliente do Google API ainda não está pronto. Tente novamente em alguns segundos.');
      return;
    }

    setIsDownloading(true);
    downloadFile(fileId);
  };

  // const downloadFile = (fileId) => {
  //   window.gapi.client.drive.files
  //     .get(
  //       {
  //         fileId: fileId,
  //         alt: 'media',
  //       },
  //       { responseType: 'arraybuffer' }
  //     )
  //     .then((response) => {
  //       const buffer = response.body;
  //       // Log para depuração
  //       console.log('Tamanho do buffer:', buffer.length);
  //       console.log('Buffer:', buffer);

  //       // Verifica se o buffer está vazio
  //       if (!buffer || buffer.length === 0) {
  //         throw new Error('O arquivo está vazio ou não pôde ser baixado.');
  //       }

  //       const base64String = btoa(
  //         new Uint8Array(buffer).reduce(
  //           (data, byte) => data + String.fromCharCode(byte),
  //           ''
  //         )
  //       );
  //       setSelectedPdf(`data:application/pdf;base64,${base64String}`);
  //       setIsDownloading(false);
  //     })
  //     .catch((error) => {
  //       console.error('Erro ao baixar o arquivo PDF:', error);
  //       console.log('Detalhes do erro:', error.result?.error || error);
  //       setIsDownloading(false);

  //       // Se o erro for relacionado a autenticação, solicita um novo token
  //       if (error.result?.error?.code === 401 || error.result?.error?.code === 403) {
  //         if (tokenClient) {
  //           alert('Autenticação necessária. Por favor, faça login novamente.');
  //           tokenClient.requestAccessToken();
  //         } else {
  //           alert('Erro: Cliente de autenticação não está disponível. Tente recarregar a página.');
  //         }
  //       } else {
  //         alert('Erro ao baixar o arquivo: ' + (error.message || 'Verifique o console para mais detalhes.'));
  //       }
  //     });
  // };



  const downloadFile = async (fileId) => {
    try {
      const accessToken = window.gapi.client.getToken().access_token;
  
      const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Erro ao fazer download: ' + response.statusText);
      }
  
      const blob = await response.blob();
  
      const reader = new FileReader();
      reader.onloadend = function () {
        const base64data = reader.result;
        setSelectedPdf(base64data);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Erro ao baixar o arquivo PDF:', error);
      alert('Erro ao baixar o PDF: ' + error.message);
    } finally {
      setIsDownloading(false);
    }
  };
  

  const closePdfViewer = () => {
    setSelectedPdf(null);
  };


  useEffect(() => {
    let touchStartY = 0;
  
    const handleTouchStart = (e) => {
      touchStartY = e.touches[0].clientY;
    };
  
    const handleTouchMove = (e) => {
      const currentY = e.touches[0].clientY;
      const diff = currentY - touchStartY;
  
      if (diff > 10 && window.scrollY === 0) {
        e.preventDefault(); // bloqueia o pull-to-refresh
      }
    };
  
    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
  
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);
  

  return (
    <div className="ordens-producao-container-ordens">
      <header className="header-container-landing-page">
        <div className="header-logo-center-landing-page">
          <label className="header-logo-center-label-landing-page">Syncs</label>
        </div>
        <div className="header-menu-right-landing-page">
          {/* Menu ou itens adicionais do cabeçalho podem ser colocados aqui */}
          <button className="btn-voltar-header-ordens-producao" onClick={() => navigate('/terminal')}>
            <MdArrowBack className="icon-voltar-header-ordens-producao" />
          </button>
        </div>
      </header>

      <div className="App-header">
        <h3 className="title-ordens">Ordens de Produção</h3>

        <div className="ordens-producao-container">
          <div className="input-group">
            <label className="label-input-ordens" htmlFor="codigo">Código</label>
            <input
              id="codigo"
              className="input-ordens-producao"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Código..."
            />
          </div>

          <div className="input-group">
            <label className="label-input-ordens" htmlFor="tipo">Tipo</label>
            <input
              id="tipo"
              className="input-ordens-producao"
              type="text"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
              placeholder="Tipo..."
            />
          </div>

          <div className="btns-ordens-producao-container">
            <button className="btn-ordens-producao" onClick={searchFile}>
              <MdOutlineManageSearch className="icons-ordens-producao" />
            </button>
          </div>
        </div>

        <div>
          {isSearching ? (
            <p>Buscando arquivo...</p>
          ) : (
            files.length > 0 && (
              <ul>
                {files.map((file) => (
                  <li key={file.id}>
                    <button className="item-list-ordens" onClick={() => handleFileClick(file.id)}>
                      {file.name}
                    </button>
                  </li>
                ))}
              </ul>
            )
          )}
        </div>

        {isDownloading && (
          <div className="overlay">
            <div className="downloading-message">
              <p>Baixando...</p>
            </div>
          </div>
        )}

        {selectedPdf && (
          <PDFViewer pdfUrl={selectedPdf} initialSearchTerm={tipo} onClose={closePdfViewer} />
        )}
      </div>
    </div>
  );
}

export default OrdensProducao;