
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useUser } from '../../contexts/userContext';
import { useCard } from '../../contexts/cardContext';
import { apiUrl, fileApiUrl } from '../../config/apiConfig';
import api from '../../services/api';

import { MdCameraAlt, MdAutorenew, MdAttachFile } from "react-icons/md";


// STYLE
import './style.css';

// Defina os setores e a função getSetorColor fora do componente
const setores = ['Proposta Comercial', 'Proposta Comercial Alterada', 'Check List Medição', 'Check List Medição Alterado', 'Contrato Assinado', 'Contrato Não Assinado', 'Adiantamento Assinado', 'Adiantamento Não Assinado', 'Projeto da Obra', 'Termo de Medição Assinado', 'Termo de Medição Não Assinado', 'Mapa de Corte', 'Check List de Instalação'];

const getSetorColor = (setor) => {



  const updatePrazoEntrega = async (numeroPedido, dtPrazoEntrega) => {
    try {
      const response = await axios.put(`${apiUrl}/faktory/pedidovenda/${numeroPedido}/prazoentrega`, {
        dtPrazoEntrega,
      });
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar prazo de entrega:', error);
      throw error;
    }
  };


  const cores = {
    'Proposta Comercial': '#FF6F61', // Coral Vibrante
    'Check List Medição': '#FF6F61', // Coral Vibrante
    'Check List Medição Alterado': '#6B5B95', // Roxo Médio
    'Contrato Assinado': '#88B04B', // Verde Musgo Claro
    'Contrato Não Assinado': '#F7CAC9', // Rosa Suave
    'Adiantamento Assinado': '#92A8D1', // Azul Cinza Claro
    'Adiantamento Não Assinado': '#F7786B', // Vermelho Coral
    'Proposta Comercial Alterada': '#FF6F61', // Coral Vibrante
    'Projeto da Obra': '#3B5998', // Azul Real
    'Termo de Medição Assinado': '#88B04B', // Verde Musgo Claro
    'Termo de Medição Não Assinado': '#F7CAC9', // Rosa Suave
    'Mapa de Corte': '#034F84', // Azul Escuro
    'Check List de Instalação': '#DE7A22', // Laranja Brilhante
  };


  return cores[setor] || '#000'; // Retorna preto se o setor não for encontrado
};


const sortAnexosBySetor = (anexos) => {
  return anexos.sort((a, b) => setores.indexOf(a.setor) - setores.indexOf(b.setor));
};

const Anexos = ({ idCard, cardData }) => {
  const { user, users, empresa } = useUser();
  const { setOpenCloseAnexosModal } = useCard();

  const [fileLinksCard, setFileLinksCard] = useState([]);
  //const MAX_FILE_SIZE_BYTES = 10 * 1024 * 1024; // 10 MB
  const [infoUpload, setInfoUpload] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exigirSalvar, setExigirSalvar] = useState(false);
  const [comment, setComment] = useState('');
  const [capturedImage, setCapturedImage] = useState(null);

  const [selectedSetor, setSelectedSetor] = useState('Selecione');





  const [isUsingFrontCamera, setIsUsingFrontCamera] = useState(false);
  const [isSavingPhoto, setIsSavingPhoto] = useState(false);





  useEffect(() => {
    if (!idCard) return;



    const fetchAnexos = async () => {
      setIsLoading(true);
      try {
        // const response = await axios.get(`${apiUrl}/card/${idCard}/anexos`);
        const response = await api.get(`/card/${idCard}/anexos`);

        const fileLinks = response.data
          .filter(anexo => anexo.user_id === user.id || !anexo.privado)
          .map(anexo => ({
            id: anexo.id,
            name: anexo.nome_arquivo,
            link: anexo.url.includes('firebasestorage') ? anexo.url : `${fileApiUrl}/uploads/${anexo.nome_arquivo}`, // Corrigido
            tamanho: anexo.tamanho,
            tipo: anexo.tipo_arquivo,
            createdAt: anexo.created_at,
            url: anexo.url, // Deixe o URL original intacto
            comment: anexo.comment,
            setor: anexo.setor,
            privado: anexo.privado,
            userId: anexo.user_id
          }));

        setFileLinksCard(sortAnexosBySetor(fileLinks));
      } catch (error) {
        console.error("Erro ao carregar anexos:", error);
      }
      setIsLoading(false);
    };


    fetchAnexos();
  }, [idCard, user.id]);





  const normalizeFileName = (fileName) => {
    // Remover caracteres especiais e espaços
    let cleanedName = fileName.replace(/[^a-zA-Z0-9.-]/g, '_');

    // Limitar o tamanho do nome
    if (cleanedName.length > 100) {
      cleanedName = cleanedName.substring(0, 100);
    }

    return cleanedName;
  };




  // const handleFileUpload = async (e) => {
  //   setIsLoading(true);

  //   if (!selectedSetor) {
  //     window.alert('Por favor, selecione um setor antes de fazer o upload.');
  //     setIsLoading(false);
  //     return;
  //   }

  //   const files = e.target.files;
  //   if (files.length > 0) {
  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];
  //       //if (file.size <= MAX_FILE_SIZE_BYTES) {
  //       setIsSaving(true);
  //       setExigirSalvar(true);

  //       const fileParts = file.name.split('.');
  //       const extension = fileParts.pop();
  //       const fileName = fileParts.join('.');

  //       const timestamp = Date.now();
  //       const normalizedFileName = normalizeFileName(fileName);
  //       const newFileName = `${normalizedFileName}_C${idCard}_U${user.id}_T${timestamp}.${extension}`;
  //       const renamedFile = new File([file], newFileName, { type: file.type });

  //       const formData = new FormData();
  //       formData.append('file', renamedFile);
  //       formData.append('empresaId', user.empresa_id);
  //       formData.append('empresaName', empresa.nome);
  //       formData.append('cardId', idCard);
  //       formData.append('userId', user.id); // Adicione esta linha
  //       formData.append('clientName', cardData.name);
  //       formData.append('comment', comment);
  //       formData.append('setor', selectedSetor);

  //       try {
  //         const response = await axios.post(`${fileApiUrl}/uploads`, formData, {
  //           headers: {
  //             'Content-Type': 'multipart/form-data'
  //           }
  //         });

  //         const newAnexo = {
  //           empresa_id: user.empresa_id,
  //           url: response.data.filePath,
  //           nome_arquivo: newFileName,
  //           tamanho: file.size,
  //           tipo_arquivo: file.type,
  //           created_at: new Date().toISOString(),
  //           comment: comment,
  //           setor: selectedSetor,
  //           user_id: user.id // Adicione esta linha
  //         };

  //         const dbResponse = await axios.post(`${apiUrl}/card/${idCard}/add-anexo`, newAnexo);

  //         setFileLinksCard((prevLinks) => sortAnexosBySetor([...prevLinks, {
  //           id: dbResponse.data.id,
  //           name: dbResponse.data.nome_arquivo,
  //           link: `${fileApiUrl}/uploads/${newFileName}`,
  //           tamanho: dbResponse.data.tamanho,
  //           tipo_arquivo: dbResponse.data.tipo_arquivo,
  //           createdAt: dbResponse.data.created_at,
  //           comment: dbResponse.data.comment,
  //           setor: dbResponse.data.setor,
  //           userId: dbResponse.data.user_id // Adicione esta linha
  //         }]));

  //         setIsLoading(false);

  //         // Verificar o setor e enviar o e-mail
  //         if (
  //           selectedSetor === 'Check List Medição' ||
  //           selectedSetor === 'Check List Medição Alterado'
  //       ) {
  //           await handleSendEmail(cardData);
  //       }

  //       } catch (error) {
  //         console.error("Erro ao enviar o arquivo:", error);
  //         window.alert('Erro ao incluir anexo!');
  //         setIsLoading(false);
  //       }
  //       setIsSaving(false);
  //       setExigirSalvar(false);
  //       //}
  //     }
  //   }
  // };


  const handleFileUpload = async (e) => {
    setIsLoading(true);

    if (!selectedSetor || selectedSetor === 'Selecione') {
      window.alert('Por favor, selecione um setor antes de fazer o upload.');
      setIsLoading(false);
      return;
    }

    const files = e.target.files;
    if (files.length > 0) {
      // Verifica se o setor é "Check List Medição" ou "Check List Medição Alterado"
      if (selectedSetor === 'Check List Medição' || selectedSetor === 'Check List Medição Alterado') {
        try {
          await handleSendEmail(cardData); // Envia o e-mail antes do upload
        } catch (error) {
          console.error('Erro ao enviar o e-mail:', error);
          window.alert('Erro ao enviar o e-mail. Operação cancelada.');
          setIsLoading(false);
          return;
        }
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setIsSaving(true);
        setExigirSalvar(true);

        const fileParts = file.name.split('.');
        const extension = fileParts.pop();
        const fileName = fileParts.join('.');

        const timestamp = Date.now();
        const normalizedFileName = normalizeFileName(fileName);
        const newFileName = `${normalizedFileName}_C${idCard}_U${user.id}_T${timestamp}.${extension}`;
        const renamedFile = new File([file], newFileName, { type: file.type });

        const formData = new FormData();
        formData.append('file', renamedFile);
        formData.append('empresaId', user.empresa_id);
        formData.append('empresaName', empresa.nome);
        formData.append('cardId', idCard);
        formData.append('userId', user.id);
        formData.append('clientName', cardData.name);
        formData.append('comment', comment);
        formData.append('setor', selectedSetor);

        try {
          const response = await axios.post(`${fileApiUrl}/uploads`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          const newAnexo = {
            empresa_id: user.empresa_id,
            url: response.data.filePath,
            nome_arquivo: newFileName,
            tamanho: file.size,
            tipo_arquivo: file.type,
            created_at: new Date().toISOString(),
            comment: comment,
            setor: selectedSetor,
            user_id: user.id,
          };

          // const dbResponse = await axios.post(`${apiUrl}/card/${idCard}/add-anexo`, newAnexo);
          const dbResponse = await api.post(`/card/${idCard}/add-anexo`, newAnexo);


          setFileLinksCard((prevLinks) =>
            sortAnexosBySetor([
              ...prevLinks,
              {
                id: dbResponse.data.id,
                name: dbResponse.data.nome_arquivo,
                link: `${fileApiUrl}/uploads/${newFileName}`,
                tamanho: dbResponse.data.tamanho,
                tipo_arquivo: dbResponse.data.tipo_arquivo,
                createdAt: dbResponse.data.created_at,
                comment: dbResponse.data.comment,
                setor: dbResponse.data.setor,
                userId: dbResponse.data.user_id,
              },
            ])
          );
        } catch (error) {
          console.error('Erro ao enviar o arquivo:', error);
          window.alert('Erro ao incluir anexo!');
        }
        setIsSaving(false);
        setExigirSalvar(false);
      }
    }

    setIsLoading(false);
  };






  const handleDeleteFile = async (fileId) => {

    if (user.access_level !== 5) {
      window.alert('Apenas ADM pode excluir anexos!');
      return;  // Interrompe a execução se o usuário não for ADM
    }

    const confirmDelete = window.confirm('Tem certeza que deseja excluir este arquivo?');
    if (confirmDelete) {
      setExigirSalvar(true);
      setIsLoading(true);
      const file = fileLinksCard.find(item => item.id === fileId);
      if (file) {
        try {
          // const anexoResponse = await axios.get(`${apiUrl}/card/buscar-por-id?id=${file.id}`);
          const anexoResponse = await api.get(`/card/buscar-por-id?id=${file.id}`);


          if (anexoResponse.data && anexoResponse.data.id) {
            // const deletedAnexo = await axios.delete(`${apiUrl}/card/${anexoResponse.data.id}/delete-anexo`);
            const deletedAnexo = await api.delete(`/card/${anexoResponse.data.id}/delete-anexo`);


            if (deletedAnexo.data) {
              const response = await axios.delete(`${fileApiUrl}/uploads/${file.name}`, {
                params: {
                  empresaId: user.empresa_id,
                  cardId: idCard,
                  userId: user.id,
                  clientName: cardData.name
                }
              });

              if (response.data.message === 'File deleted successfully') {
                const updatedFileLinks = fileLinksCard.filter(item => item.id !== fileId);
                setFileLinksCard(updatedFileLinks);
                window.alert('Anexo excluído com sucesso!');
              } else {
              }
            } else {
            }
          } else {
          }
        } catch (error) {
          console.error("Erro ao excluir o arquivo do servidor ou do banco de dados:", error);
          window.alert('Erro ao excluir anexo!');
        }
        setIsLoading(false);
        setExigirSalvar(false);
      }
    }
  };





  const fileInputRef = useRef(null);


  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);



  const openCamera = () => {
    setIsCameraOpen(true);
    const constraints = {
      video: {
        facingMode: isUsingFrontCamera ? 'user' : 'environment'
      }
    };
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((error) => {
        console.error("Erro ao acessar a câmera:", error);
        setIsCameraOpen(false);
      });
  };


  const switchCamera = () => {
    setIsUsingFrontCamera((prevState) => !prevState);
    openCamera();
  };



  const capturePhoto = () => {
    setIsSavingPhoto(true);
    const context = canvasRef.current.getContext('2d');
    canvasRef.current.width = videoRef.current.videoWidth;
    canvasRef.current.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    const photo = canvasRef.current.toDataURL('image/png');

    // Convert data URL to file and handle upload
    handleUploadCapturedPhoto(photo);
  };



  const handleUploadCapturedPhoto = async (photo) => {
    const blob = await (await fetch(photo)).blob();
    const file = new File([blob], `captured_image_C${idCard}_U${user.id}_T${Date.now()}.png`, { type: 'image/png' });

    const formData = new FormData();
    formData.append('file', file);
    formData.append('empresaId', user.empresa_id);
    formData.append('empresaName', empresa.nome);
    formData.append('cardId', idCard);
    formData.append('userId', user.id);
    formData.append('clientName', cardData.name);
    formData.append('comment', comment);
    formData.append('setor', selectedSetor);

    try {
      const response = await axios.post(`${fileApiUrl}/uploads`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const newAnexo = {
        empresa_id: user.empresa_id,
        url: response.data.filePath,
        nome_arquivo: file.name,
        tamanho: file.size,
        tipo_arquivo: file.type,
        created_at: new Date().toISOString(),
        comment: comment,
        setor: selectedSetor,
        user_id: user.id // Adicione esta linha
      };

      // const dbResponse = await axios.post(`${apiUrl}/card/${idCard}/add-anexo`, newAnexo);
      const dbResponse = await api.post(`/card/${idCard}/add-anexo`, newAnexo);


      setFileLinksCard((prevLinks) => sortAnexosBySetor([...prevLinks, {
        id: dbResponse.data.id,
        name: dbResponse.data.nome_arquivo,
        link: `${fileApiUrl}/uploads/${file.name}`,
        tamanho: dbResponse.data.tamanho,
        tipo_arquivo: dbResponse.data.tipo_arquivo,
        createdAt: dbResponse.data.created_at,
        comment: dbResponse.data.comment,
        setor: dbResponse.data.setor,
        userId: dbResponse.data.user_id // Adicione esta linha
      }]));

      setIsCameraOpen(false); // Close the camera modal
    } catch (error) {
      console.error("Erro ao enviar a foto capturada:", error);
      window.alert('Erro ao incluir anexo!');
    } finally {
      // Ensure camera is closed
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
      setIsSavingPhoto(false);
    }
  };




  const closeCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
    setIsCameraOpen(false);
  };




  const openFirebaseFile = (fileUrl) => {
    console.log(fileUrl)
    window.open(fileUrl, '_blank');
  };


  const downloadServerFile = (fileUrl, fileName, empresaId, cardId, userId, clientName) => {
    const cacheBustingParam = `?t=${new Date().getTime()}`;
    const downloadUrl = `${fileUrl}${cacheBustingParam}&empresaId=${empresaId}&cardId=${cardId}&userId=${userId}&clientName=${clientName}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleFileClick = (fileUrl, fileName, empresaId, cardId, userId, clientName) => {
    // Verifica se o link é do Firebase
    if (fileUrl.includes('firebasestorage.googleapis.com')) {
      openFirebaseFile(fileUrl);
    } else {
      downloadServerFile(fileUrl, fileName, empresaId, cardId, userId, clientName);
    }
  };



  const togglePrivado = async (fileId, newPrivadoStatus) => {
    try {
      // const response = await axios.put(`${apiUrl}/card/${fileId}/toggle-privado`, { privado: newPrivadoStatus });
      const response = await api.put(`/card/${fileId}/toggle-privado`, { privado: newPrivadoStatus });

      if (response.data.success) {
        setFileLinksCard((prevLinks) =>
          prevLinks.map((file) =>
            file.id === fileId ? { ...file, privado: newPrivadoStatus } : file
          )
        );
      } else {
        window.alert('Erro ao alterar a privacidade do anexo.');
      }
    } catch (error) {
      console.error("Erro ao alterar a privacidade do anexo:", error);
      window.alert('Erro ao alterar a privacidade do anexo.');
    }
  };






  const getUserName = (userId, users, currentUser) => {
    if (!users) {
      return 'Desconhecido';
    }

    if (userId === currentUser.id) {
      return currentUser.username;
    }

    const user = users.find(user => user.id === userId);
    return user ? user.username : 'Desconhecido';
  };















  const handleSendEmail = async (pedidoData) => {

    const emailData = {
      to: empresa?.email_check_list || 'email@default.com',
      subject: 'Check List de Medição',
      text: `Check List de Medição Anexado no CRM para o cliente ${cardData.name}! PrefGest: ${cardData.document_number} - PrefWeb: ${cardData.second_document_number} - Número Pedido: ${cardData.pedido_number}`
    };

    try {
      // const response = await axios.post(`${apiUrl}/users/send-email/${empresa?.id || 0}`, emailData);
      const response = await api.post(`/users/send-email/${empresa?.id || 0}`, emailData);

      alert('Email com as informações enviado para o setor responsável!');
    } catch (error) {
      console.error('Erro ao enviar o email:', error);
    }
  };




  const hasAnexoWithSetor = (setor) => {
    return fileLinksCard.some(file => file.setor === setor);
  };


  return (
    <div className='geral-modal'>
      {isLoading && <div className="loading-overlay">Aguarde...</div>}
      <div className={`geral-container ${isLoading || isSaving ? 'loading' : ''}`}>
        <div className='geral-header'>
          <div className='geral-header-title'>
            <label>Anexos</label>
          </div>
          <button className="geral-header-close-button" onClick={() => setOpenCloseAnexosModal(false)}>X</button>
        </div>
        <label className='info-upload-arquivo'>{infoUpload}</label>
        <ul className='geral-body-container'>
          {fileLinksCard && fileLinksCard.length > 0 && (
            fileLinksCard



              .map((file, index) => {
                const displayName = file.name ? file.name.replace(/_\d+$/, '') : 'Nome não disponível';
                const isFirebaseStorageLink = file.url && file.url.includes('firebasestorage');
                const setorColor = getSetorColor(file.setor);




                return (
                  <li className='item-arquivo' key={index}>
                    <div className='file-info'>



                      {isFirebaseStorageLink ? (
                        <a style={{ display: 'none' }} className='link-anexo' href={`${file.url}`} target="_blank" rel="noopener noreferrer">
                          {index + 1}
                        </a>
                      ) : (
                        <a style={{ display: 'none' }} className='link-anexo' href={`${file.link}`} target="_blank" rel="noopener noreferrer">
                          {index + 1}
                        </a>
                      )}

                      <div className='descricao-anexos'>
                        <span style={{ backgroundColor: setorColor, marginLeft: '0px', padding: '5px 5px', borderRadius: '3px', color: '#fff' }}>
                          {file.setor ? file.setor : ''}
                        </span>
                      </div>
                      <div className='descricao-anexos'>Nome: {displayName || 'Desconhecido'}</div>
                      <div className='descricao-anexos'>Tipo: {file.tipo || 'Desconhecido'}</div>
                      <div className='descricao-anexos'>Tamanho: {file.tamanho ? (file.tamanho / 1024).toFixed(2) + ' KB' : 'Desconhecido'}</div>
                      <div className='descricao-anexos'>Criado por: {getUserName(file.userId, users, user)}</div>
                      <div className='descricao-anexos'>Criado em: {file.createdAt ? new Date(file.createdAt).toLocaleString() : 'Desconhecido'}</div>
                      <div className='descricao-anexos'>Comentários: {file.comment ? file.comment : ''}</div>

                      <div className='descricao-anexos'>
                        Acesso:
                        <button style={{ backgroundColor: file.privado ? 'red' : '' }} className='btn-private-anexo' disabled={file.userId !== user.id} onClick={() => togglePrivado(file.id, !file.privado)}>
                          {file.privado ? 'Privado' : 'Público'}
                        </button>
                      </div>

                      <div className='container-btns-anexo-item'>


                        <button className='btn-delete-anexo' onClick={() => handleDeleteFile(file.id)}>Excluir</button>
                        {/* <button className='btn-download-anexo' onClick={() => handleDownloadFile(file.link, file.name, user.empresa_id, idCard, user.id, cardData.name)}>Baixar</button> */}

                        <button className='btn-download-anexo' onClick={() => handleFileClick(file.link, file.name, user.empresa_id, idCard, user.id, cardData.name)}>Baixar</button>


                      </div>

                    </div>
                  </li>
                );
              })
          )}
        </ul>

        <div className="geral-footer">
          <input
            style={{ display: 'none' }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Comentário"
            className="input-comentario"
          />
          <select
            value={selectedSetor}
            onChange={(e) => setSelectedSetor(e.target.value)}
            className="select-setor"
          >
            <option value="" >
              Selecione
            </option>
            {/* {setores
              .filter(setor => user.access_level === 5 || !['Contrato Assinado', 'Adiantamento Assinado', 'Contrato Assinado','Contrato Não Assinado', 'Adiantamento Não Assinado', 'Adiantamento Assinado', 'Termo de Medição Assinado', 'Termo de Medição Não Assinado', 'Adiantamento Assinado', 'Adiantamento Não Assinado'].includes(setor))

              .map((setor, index) => (
                <option key={index} value={setor}>{setor}</option>
              ))} */}

            {setores
              .filter(setor =>
                // Permissão: apenas usuários com access_level === 5 podem ver certos setores
                (user.access_level === 5 || ![
                  'Contrato Assinado',
                  'Adiantamento Assinado',
                  'Contrato Não Assinado',
                  'Adiantamento Não Assinado',
                  'Termo de Medição Assinado',
                  'Termo de Medição Não Assinado',
                ].includes(setor))
                &&
                // Regra extra: oculta "Check List Medição" se já houver anexo com esse setor
                !(setor === 'Check List Medição' && hasAnexoWithSetor('Check List Medição'))
              )
              .map((setor, index) => (
                <option key={index} value={setor}>{setor}</option>
              ))
            }


          </select>

          <input
            type="file"
            ref={fileInputRef}
            className="input-anexos"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
          {/* <button
            type="button"
            className="btn-camera"
            onClick={() => { if(true){ fileInputRef.current.click()} else {    const confirmDelete = window.confirm('Favor informar um comentário');
            }}}
          >
            <MdAttachFile />
          </button> */}

          <button
            type="button"
            className="btn-camera"
            onClick={() => {
              if (selectedSetor && selectedSetor !== 'Selecione') {
                //alert(selectedSetor)
                fileInputRef.current.click();
              } else {
                window.alert('Por favor, selecione uma finalidade na lista ao lado.');
              }
            }}
          >
            <MdAttachFile />
          </button>


          <button
            type="button"
            className="btn-camera"
            //onClick={openCamera}

            onClick={() => {
              if (selectedSetor && selectedSetor !== 'Selecione') {
                openCamera();
              } else {
                window.alert('Por favor, selecione uma finalidade na lista ao lado.');
              }
            }}

          >
            <MdCameraAlt />
          </button>

          {isCameraOpen && (
            <div className="camera-modal">
              <video ref={videoRef} className="video-stream"></video>
              <div className='btns-cam-anexos'>
                <button onClick={closeCamera} className="capture-button" disabled={isSavingPhoto}>
                  Cancelar
                </button>
                <button onClick={switchCamera} className="switch-camera-button" disabled={isSavingPhoto}><MdAutorenew /></button>
                <button onClick={capturePhoto} className="capture-button" disabled={isSavingPhoto}>
                  {isSavingPhoto ? 'Salvando...' : 'Capturar'}
                </button>
              </div>
            </div>
          )}
          <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
        </div>
      </div>
    </div>
  );

};

export default Anexos;
